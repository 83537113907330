import React from "react";
import "../../admin/css/sucess.css";
import { useEffect } from "react";
import axios from "axios";

function Sucess() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Function to get query parameters from the URL
    const getQueryParam = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the values of the query parameters
    const trxref = getQueryParam("trxref");
    const reference = getQueryParam("reference");
    console.log(trxref, reference);

    // Make an Axios request to submit the values to an endpoint
    const submitData = async () => {
      try {
        // const response = await axios.get(
        //   `https://apis.unicconacademy.com/api/handle-payment-callback/?trxref=${trxref}&reference=${reference}`
        // );
        const response = await axios.get(
          "https://apis.unicconacademy.com/api/handle-payment-callback",
          {
            params: {
              trxref: trxref,
              reference: reference,
            },
          }
        );

        // Handle the response as needed
        console.log(response.data);
      } catch (error) {
        // Handle errors
        console.error("Error submitting data:", error);
      }
    };

    // Check if both trxref and reference are present before submitting
    if (trxref && reference) {
      submitData();
    }
  }, []);

  return (
    <div className="wrapperSucess tw-py-20 tw-px-5">
      <div className="success tw-flex tw-justify-center tw-items-center">
        <h1>
          <ion-icon
            name="checkmark-circle"
            className="tw-text-[50px] tw-w-20 tw-h-20"
          ></ion-icon>
        </h1>
        <h2>Payment Completed!</h2>
      </div>
    </div>
  );
}

export default Sucess;
