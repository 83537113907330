import banner_bg from "../assets/banner_bg.jpg";
import Instructor from "./HomePageSections/Instructor";
const About = () => {
  return (
    <main className="main-area fix">
      <section
        className="breadcrumb-area breadcrumb-bg"
        style={{ backgroundImage: `url(${banner_bg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h3 className="title">About Us</h3>
                <nav className="breadcrumb">
                  <span>
                    <a href="/">Home</a>
                  </span>
                  <span className="breadcrumb-separator">
                    <i className="fas fa-angle-right"></i>
                  </span>
                  <span>About Us</span>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area-two">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6">
              <div className="about__title-wrap">
                <div className="section__title">
                  <span className="sub-title">Who we are</span>
                  <h2
                    className="title tg-svg"
                    style={{ textTransform: "none" }}
                  >
                    UNICCON Academy is a
                    <span className="position-relative">
                      specialised digital
                    </span>{" "}
                    skills training centre.
                  </h2>
                </div>
                <p className="fw-medium">
                  A hub for project-based training in basic, intermediate,
                  advanced, and expert skills in various digital skills.
                </p>
                <p>
                  We provide state of the art research, prototyping, and
                  development laboratories to enhance teaching. We offer
                  multi-modal delivery methods, including in-person, virtual,
                  recorded, and a blended system, to suit requests.
                </p>
                <div className="tg-button-wrap">
                  <a href="/pricing" className="btn tg-svg">
                    <span className="text">Explore Courses</span>{" "}
                    <img
                      className="svg-icon"
                      id="about-btn"
                      src="assets/img/icons/btn-arrow.svg"
                      alt="jd"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-11">
              <div className="about__images-wrap">
                <div className="column">
                  <img
                    src="assets/img/home/about_one.jpg"
                    className="tw-w-full tw-h-auto sm:tw-h-[200px]"
                    alt="img"
                  />
                </div>
                <div className="column">
                  <img
                    src="assets/img/home/about_two.jpg"
                    className="tw-w-full tw-h-auto sm:tw-h-[200px]"
                    alt="img"
                  />
                  <img
                    src="assets/img/home/about_three.jpg"
                    className="tw-w-full tw-h-auto sm:tw-h-[200px]"
                    alt="img"
                  />
                </div>
                <div className="about__shapes">
                  <img
                    src="assets/img/objects/about_shape01.png"
                    alt="img"
                    className="about-shape-01"
                    data-aos="fade-down-left"
                  />
                  <img
                    src="assets/img/objects/about_shape02.png"
                    alt="img"
                    className="about-shape-02"
                    data-aos="fade-up-right"
                  />
                  <img
                    src="assets/img/objects/about_shape03.png"
                    alt="img"
                    className="about-shape-03 rotateme"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="fact-area fact-bg"
        style={{ background: "url(assets/img/bg/fact_bg.jpg)" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-9">
              <div className="section__title text-center mb-50">
                <span className="sub-title">Our Pedigree</span>
                <h2 className="title tg-svg">
                  We collaborate{" "}
                  <span className="position-relative">
                    <span
                      className="svg-icon"
                      id="fact-title"
                      data-svg-icon="assets/img/icons/title_shape.svg"
                    ></span>{" "}
                    with a broad mix of
                  </span>
                  partners on diverse projects
                </h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-sm-6">
              <div className="fact__item-two text-center">
                <div className="fact__icon-two">
                  <i className="flaticon-webinar"></i>
                </div>
                <div className="fact__content-two">
                  <p>
                    Curriculum development and training course content for
                    Technical Teachers and Instructors (TTIs). As industry
                    partners, we supported the World Bank and the Federal
                    Ministry of Education's IDEAS project to train in digital
                    skills
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ol-sm-6">
              <div className="fact__item-two text-center">
                <div className="fact__icon-two">
                  <i className="flaticon-graduates"></i>
                </div>
                <div className="fact__content-two">
                  <p>
                    A Training Provider in the National Board Technical
                    Education (NBTE)'s National Skills Qualification (NSQ). This
                    project supports the provision of ICT training.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="fact__item-two text-center">
                <div className="fact__icon-two">
                  <i className="flaticon-countries"></i>
                </div>
                <div className="fact__content-two">
                  <p>
                    A Training Provider for the World Bank's Innovation Grant
                    Facility to provide digital skills training and development
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6  col-sm-6">
              <div className="fact__item-two text-center">
                <div className="fact__icon-two">
                  <i className="flaticon-trophy"></i>
                </div>
                <div className="fact__content-two">
                  <p>
                    Supported the National Digital Literacy Framework (NDLF) by
                    creating content in the six competence areas and the 23
                    competencies. A project of the Federal Ministry of
                    Communications, Innovation and (NITDA).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-area-two position-relative">
        <div
          className="cta__bg"
          data-background="assets/img/bg/cta_bg.jpg"
        ></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="cta__content">
                <p>Apply for Scholarships</p>
                <h2 className="title">Scholarship Offer</h2>
                <h5 className="sub-title">
                  Chuksprime Foundation offers you the opportunity to get a
                  deduction in the amount to be paid for a course
                </h5>
                <div className="tg-button-wrap justify-content-center">
                  <a href="/pricing" className="btn tg-svg">
                    <span className="text">Become a Student</span>{" "}
                    <span
                      className="svg-icon"
                      id="cta-btn-2"
                      data-svg-icon="assets/img/icons/btn-arrow.svg"
                    ></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta__shapes">
          <img
            src="assets/img/objects/cta_shape001.svg"
            alt="img"
            className="position-absolute"
            data-aos="fade-down-right"
            data-aos-delay="300"
          />
          <img
            src="assets/img/objects/cta_shape002.png"
            alt="img"
            className="position-absolute"
          />
          <img
            src="assets/img/objects/cta_shape003.svg"
            alt="img"
            className="position-absolute"
            data-aos="fade-up-left"
            data-aos-delay="300"
          />
        </div>
      </section>

      <Instructor />
    </main>
  );
};

export default About;
