import React from "react";
import instructor_cyril from "../assets/images/instructors/instructor_cyril.png";
import instructor_helen from "../assets/images/instructors/instructor_helen.png";
import instructor_mahmoud from "../assets/images/instructors/instructor_mahmoud.png";
import instructor_sandra from "../assets/images/instructors/instructor_sandra.png";
import instructor_phillip from "../assets/images/instructors/instructor_phillip.png";
import banner_bg from "../assets/banner_bg.jpg";

const AllInstructors = () => {
  return (
    <>
      <section
        className="breadcrumb-area breadcrumb-bg"
        style={{ backgroundImage: `url(${banner_bg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h3 className="title">Our Instructors</h3>
                <nav className="breadcrumb">
                  <span>
                    <a href="/">Home</a>
                  </span>
                  <span className="breadcrumb-separator">
                    <i className="fas fa-angle-right"></i>
                  </span>
                  <span>Our Instructors</span>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="instructor-area section-pt-120 section-pb-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="instructor__item"
                data-aos="fade-up"
                data-aos-delay="700"
              >
                <div className="instructor__img">
                  <div
                    className="instructor__shape"
                    style={{
                      backgroundColor: "#E8EDFF",
                      borderRadius: "135px 0 0 0",
                    }}
                  ></div>
                  <a href="/#">
                    <img src={instructor_sandra} alt="instructor" />
                  </a>
                </div>
                <div className="instructor__content">
                  <div className="left">
                    <span className="designation">Data Analytics</span>
                    <h4 className="name">
                      <a href="/#">Sandra Mahanan</a>
                    </h4>
                  </div>
                  <div className="right">
                    <span className="share">
                      <i className="flaticon-share"></i>
                    </span>
                    <ul className="social-list list-wrap">
                      <li>
                        <a href="#/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="instructor__item"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="instructor__img">
                  <div
                    className="instructor__shape"
                    style={{
                      backgroundColor: "#E2FAFF",
                      borderRadius: "135px 0 0 0",
                    }}
                  ></div>
                  <a href="/#">
                    <img src={instructor_mahmoud} alt="instructor" />
                  </a>
                </div>
                <div className="instructor__content">
                  <div className="left">
                    <span className="designation">Blockchain Technology</span>
                    <h4 className="name">
                      <a href="/#">Mahmud Oloyede</a>
                    </h4>
                  </div>
                  <div className="right">
                    <span className="share">
                      <i className="flaticon-share"></i>
                    </span>
                    <ul className="social-list list-wrap">
                      <li>
                        <a href="#/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="instructor__item"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="instructor__img">
                  <div
                    className="instructor__shape"
                    style={{
                      backgroundColor: "#E4FFE2",
                      borderRadius: "135px 115px 115px 0",
                    }}
                  ></div>
                  <a href="/#">
                    <img src={instructor_cyril} alt="instructor" />
                  </a>
                </div>
                <div className="instructor__content">
                  <div className="left">
                    <span className="designation">Web Development</span>
                    <h4 className="name">
                      <a href="/#">ThankGod Cyril</a>
                    </h4>
                  </div>
                  <div className="right">
                    <span className="share">
                      <i className="flaticon-share"></i>
                    </span>
                    <ul className="social-list list-wrap">
                      <li>
                        <a href="#/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="instructor__item"
                data-aos="fade-up"
                data-aos-delay="900"
              >
                <div className="instructor__img">
                  <div
                    className="instructor__shape"
                    style={{
                      backgroundColor: "#FFE2E2",
                      borderRadius: "135px 115px 115px 0",
                    }}
                  ></div>
                  <a href="/#">
                    <img src={instructor_phillip} alt="instructor" />
                  </a>
                </div>
                <div className="instructor__content">
                  <div className="left">
                    <span className="designation">Data Science</span>
                    <h4 className="name">
                      <a href="/#">Phillip Oguntuyi</a>
                    </h4>
                  </div>
                  <div className="right">
                    <span className="share">
                      <i className="flaticon-share"></i>
                    </span>
                    <ul className="social-list list-wrap">
                      <li>
                        <a href="#/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div
                className="instructor__item"
                data-aos="fade-up"
                data-aos-delay="900"
              >
                <div className="instructor__img">
                  <div
                    className="instructor__shape"
                    style={{
                      backgroundColor: "#FFE2E2",
                      borderRadius: "135px 115px 115px 0",
                    }}
                  ></div>
                  <a href="/#">
                    <img src={instructor_helen} alt="instructor" />
                  </a>
                </div>
                <div className="instructor__content">
                  <div className="left">
                    <span className="designation">UI & UX Design</span>
                    <h4 className="name">
                      <a href="/#">Helen Nnamani</a>
                    </h4>
                  </div>
                  <div className="right">
                    <span className="share">
                      <i className="flaticon-share"></i>
                    </span>
                    <ul className="social-list list-wrap">
                      <li>
                        <a href="#/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#/">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllInstructors;
