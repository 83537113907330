import React, { useState } from "react";
import caret_right from "../../../images/common/caret_right.svg";
import StatusMessage from "../../common/StatusMessage";

const InputFields = ({ onAdd }) => {
  const [message, setMessage] = useState(false);

  const [formData, setFormData] = useState({
    topic: "",
    subtopics: [{ subtopic: "" }],
  });

  const handleAddSubTopic = () => {
    setFormData({
      ...formData,
      subtopics: [...formData.subtopics, { subtopic: "" }],
    });
  };

  const handleSubTopicChange = (index, value) => {
    const updatedSubtopics = [...formData.subtopics];
    updatedSubtopics[index] = value;

    setFormData({
      ...formData,
      subtopics: updatedSubtopics,
    });
  };

  const handleRemoveSubTopic = (index) => {
    const updatedSubtopics = [...formData.subtopics];
    updatedSubtopics.splice(index, 1);

    setFormData({
      ...formData,
      subtopics: updatedSubtopics,
    });
  };

  const handleMessage = (status, message) => {
    setMessage({
      status,
      message,
    });
  };

  const handleAdd = () => {
    if (formData.topic.trim() === "") {
      handleMessage("error", "Please enter a topic.");
      return;
    }

    onAdd(formData);

    setFormData({
      topic: "",
      subtopics: [""],
    });
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter topic"
        className="academy-admin-input tw-mt-1"
        value={formData.topic}
        onChange={(e) => setFormData({ ...formData, topic: e.target.value })}
      />
      <div className="tw-flex tw-flex-col">
        {formData.subtopics.map((subTopic, index) => (
          <div
            key={index}
            className="tw-flex tw-w-[95%] outline-fix tw-pl-[5%] tw-mb-2"
          >
            <div className="tw-bg-[#F7F9FB] tw-rounded-[6px] tw-flex tw-w-full tw-pl-[15px]">
              <img src={caret_right} alt="caret-left" className="" />
              <input
                type="text"
                value={subTopic.subtopic}
                placeholder="Enter sub-topic"
                className="tw-border-0 tw-bg-[transparent] tw-h-[42px] tw-pl-2 tw-w-full"
                onChange={(e) => handleSubTopicChange(index, e.target.value)}
              />
            </div>

            <button
              type="button"
              onClick={() => handleRemoveSubTopic(index)}
              className="tw-w-[42px] tw-h-[42px] tw-text-[#782525] focus:tw-outline-[none] tw-bg-[transparent] tw-font-extrabold tw-border-0  tw-cursor-pointer"
            >
              X
            </button>
            <button
              type="button"
              onClick={handleAddSubTopic}
              className="tw-w-[42px] tw-h-[42px] tw-cursor-pointer tw-bg-[#F7F9FB] tw-text-[#0D6EFD] tw-border-0 tw-text-[20px]"
            >
              +
            </button>
          </div>
        ))}
      </div>

      <button onClick={handleAdd} className="addtopic">
        + Add Topics
      </button>
      {message && <StatusMessage message={message} setMessage={setMessage} />}
    </div>
  );
};

export default InputFields;
