import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./layout.css";
import Sidebar from "../sidebar/Sidebar";
import menu from "../../images/common/menu.svg";

const Mainlayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [activePath, setActivePath] = useState(false);

  const { pathname: path } = useLocation();

  const toggleSidebar = () => {
    console.log(isSidebarOpen);
    setSidebarOpen(!isSidebarOpen);
  };

  React.useEffect(() => {
    switch (path) {
      case "/dashboard":
        setActivePath("Dashboard");
        break;
      case "/courses":
        setActivePath("Courses");
        break;
      case "/admin/scholarships":
        setActivePath("Scholarship");
        break;
      case "/admin/organization-setting":
        setActivePath("Organization Setting");
        break;
      case "/admin/quiz-settings":
        setActivePath("Quiz Setting");
        break;
      case "/admin/quiz-scores":
        setActivePath("Quiz Scores");
        break;
      case "/admin/add-quiz-questions":
        setActivePath("Add Quiz Questions");
        break;
      case "/admin/certificate":
        setActivePath("Student Certificates");
        break;
      case "/admin/user-management":
        setActivePath("User Management");
        break;
      case "/courses/add-course":
        setActivePath("Add Course");
        break;
      case "/courses/course-detail":
        setActivePath("View Course");
        break;
      case "courses/:id/edit-course":
        setActivePath("Edit Course");
        break;
      case "/admin/transactions":
        setActivePath("Transactions");
        break;
      case "/admin/send-receipt":
        setActivePath("Receipt");
        break;
      default:
        setActivePath("Admin");
    }
  }, [path]);

  return (
    <div className="tw-w-full tw-flex tw-gap-x-0 lg:tw-gap-x-5">
      <div>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </div>

      <div className="changecomponet lg:tw-p-3">
        <Header onClose={toggleSidebar} activePath={activePath} />
        <Outlet />
      </div>
    </div>
  );
};

const Header = ({ onClose, activePath }) => {
  return (
    <div className="tw-absolute tw-top-0 tw-shadow lg:tw-shadow-none tw-py-[20px] tw-w-full lg:tw-w-[50%] tw-bg-white lg:tw-bg-[transparent] tw-justify-center tw-items-center ">
      <div className="tw-flex tw-justify-between tw-px-[20px] tw-font-bold tw-gap-[6px] tw-items-center tw-text-blue-600 tw-text-lg lg:tw-text-[28px] tw-font-['Manrope'] lg:tw-mt-3 ">
        {activePath}
        <img
          src={menu}
          alt="menu"
          className="cursor-pointer lg:tw-hidden"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default Mainlayout;
