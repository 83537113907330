const NotFound = () => {
  return (
    <section className="error-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="error-wrap text-center">
              <div className="error-img">
                <img src="assets/img/others/error_img.svg" alt="img" />
              </div>
              <div className="error-content">
                <h2 className="title">
                  ERROR PAGE! <span>Sorry! This Page is Not Available!</span>
                </h2>
                <div className="tg-button-wrap">
                  <a href="/" className="btn tg-svg">
                    <span className="text">Go To Home Page</span>
                    <img src="assets/img/icons/btn-arrow.svg" alt="img" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
