import "../adminmanagement/adminmanagement.css";
import filestorage from "../scholarship/images/file-storage 1.png";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import close from "../../images/common/closes.svg";
import action from "../../images/common/action.svg";
import no_record from "../../images/common/no_record.svg";
import loading from "../../images/common/loading.svg";
import axios from "axios";
import { useAuth } from "../auth/Auth";
import SavingStatus from "../common/SavingStatus";
import StatusMessage from "../common/StatusMessage";
import formatDate from "../common/FormatDate";

const Adminmanagement = () => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [myList, setMyList] = useState([]);
  const [addAdmin, setAddAdmin] = useState(false);
  const [editAdmin, setEditAdmin] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const { token } = useAuth();
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isUserActionOpen, setIsUserActionOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/all-admin`, config)
      .then((response) => {
        setMyList(response.data);
        // console.log(response.data);
        setLoadingPage(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openAddAdmin = () => setAddAdmin(!addAdmin);
  const openEditAdmin = () => setEditAdmin(!editAdmin);
  const openDeleteAdmin = () => setDeleteAdmin(!deleteAdmin);

  const openUserAction = (id) => {
    setIsUserActionOpen(true);
    setSelectedUserId(id);
    document.body.style.overflow = "hidden";
  };

  const closeUserAction = () => {
    setIsUserActionOpen(false);
    setSelectedUserId(null);
    document.body.style.overflow = "auto";
  };

  const handleDelete = () => {
    openDeleteAdmin();
    savingApproval(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}api/remove-admin/${selectedUser.id}`,
        config
      )
      .then((response) => {
        console.log(response);

        if (response.data.success === true) {
          savingApproval(false);
          console.log("Form submission successful!", response.data);
          handleMessage("success", "User deleted successfully!");
          setSelectedUser(null);
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
        savingApproval(false);
        handleMessage("error", "Error deleting User!");
      });
  };

  const savingApproval = (status) => {
    setSaving(status);
  };

  const handleMessage = (status, message) => {
    setMessage({
      status,
      message,
    });
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return location.pathname !== "/admin/user-management" ? (
    <Outlet />
  ) : (
    <>
      <div className="uaa-container tw-w-full tw-px-[5%] lg:tw-px-[0%]">
        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center tw-mb-4 tw-mt-12 lg:tw-mt-10 tw-px-2 sm:tw-px-0">
          <div className="tw-text-neutral-950 tw-text-xl tw-font-bold tw-font-['Manrope'] tw-leading-normal tw-tracking-tight">
            Users List
          </div>
          <div className="tw-flex md:tw-flex-col tw-items-center tw-justify-center tw-flex-col-reverse md:tw-items-end md:tw-justify-end">
            <Link
              onClick={openAddAdmin}
              className="tw-px-4 tw-py-2.5 tw-mb-2 tw-text-white tw-no-underline tw-bg-blue-600 tw-rounded-md"
            >
              + Create Admin
            </Link>
            <input
              type="text"
              placeholder="Search by name..."
              className="tw-w-[100%] lg:tw-w-[384px] tw-text-[#D7DCE0] tw-border-[#D7DCE0] tw-rounded-[6px] tw-h-[35px] tw-pl-[10px] tw-text-[16px] tw-mb-3"
            />
          </div>
        </div>

        <div className="scholarshipnav">
          <p className="adminusers">Admin Users</p>
        </div>

        <>
          {myList.length === 0 ? (
            <div className="holder">
              <div className="filenotindiv">
                <img src={filestorage} alt="" />

                <h3>No record yet</h3>
              </div>
            </div>
          ) : (
            <div>
              <div className="tw-overflow-x-hidden">
                <div className="tw-block tw-overflow-x-scroll">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th className="tw-invisible md:tw-visible">
                          Date Created
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {myList.length === 0 ? (
                        <tr>
                          <td className="tw-text-center tw-text-bold">
                            No recently submitted data available
                          </td>
                        </tr>
                      ) : (
                        <>
                          {myList.map((user) => (
                            <tr key={user.id}>
                              <td>{user.name}</td>
                              <td>{user.email}</td>
                              <td className="tw-invisible md:tw-visible">
                                {user.created_at
                                  ? formatDate(user.created_at)
                                  : "No Created Date"}
                              </td>
                              <td>
                                <img
                                  src={action}
                                  alt="action"
                                  className="tw-cursor-pointer"
                                  onClick={() => openUserAction(user.id)}
                                />
                                {isUserActionOpen && (
                                  <>
                                    {selectedUserId === user.id && (
                                      <div className="tw-bg-white tw-cursor-pointer tw-w-[100px] tw-h-auto tw-py-5 tw-px-2 tw-ml-[-50px] tw-mt-[-10px] tw-gap-y-2 tw-absolute tw-rounded tw-shadow-xl tw-flex tw-flex-col tw-justify-center tw-items-center tw-z-10">
                                        <div
                                          className="hover:tw-text-[#0D6EFD]"
                                          onClick={() => {
                                            openEditAdmin();
                                            setSelectedUser(user);
                                          }}
                                        >
                                          Edit
                                        </div>
                                        <div
                                          className="hover:tw-text-[#fd0d0d]"
                                          onClick={() => {
                                            openDeleteAdmin();
                                            setSelectedUser(user);
                                          }}
                                        >
                                          Delete
                                        </div>
                                      </div>
                                    )}
                                    <div
                                      className="popup-overlay"
                                      onClick={closeUserAction}
                                    ></div>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
      {addAdmin && (
        <AddUser
          openAddAdmin={openAddAdmin}
          token={token}
          fetchData={fetchData}
          savingApproval={savingApproval}
          handleMessage={handleMessage}
        />
      )}
      {editAdmin && (
        <EditUser
          openAddAdmin={openEditAdmin}
          token={token}
          fetchData={fetchData}
          savingApproval={savingApproval}
          handleMessage={handleMessage}
          selectedUser={selectedUser}
        />
      )}
      {deleteAdmin && (
        <DeleteConfirmationModal
          openDeleteAdmin={openDeleteAdmin}
          onDelete={handleDelete}
        />
      )}
      {saving && <SavingStatus />}
      {message && <StatusMessage message={message} setMessage={setMessage} />}
    </>
  );
};

const AddUser = ({
  openAddAdmin,
  token,
  savingApproval,
  handleMessage,
  fetchData,
}) => {
  const handleClose = () => {
    openAddAdmin();
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        return value.length < 3
          ? "Name must be at least 3 characters long"
          : "";
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address";
      case "password":
        return value.length < 6
          ? "Password must be at least 6 characters long"
          : "";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const key in formData) {
      const error = validateInput(key, formData[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    handleClose();
    savingApproval(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/add-admin`, formData, config)
      .then((response) => {
        console.log(response);

        if (response.data.success === true) {
          savingApproval(false);
          console.log("Form submission successful!", response.data);
          handleMessage("success", "User added success!");
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
        savingApproval(false);
        handleMessage("error", "Error creating User!");
      });
  };

  return (
    <div className="admin-overlay tw-w-full">
      <div
        className="tw-absolute tw-bg-[#0002] tw-top-0 tw-left-0 tw-w-full tw-h-full"
        onClick={handleClose}
      ></div>
      <div className="tw-absolute tw-right-0 tw-top-[200px] lg:tw-top-0 tw-w-full lg:tw-w-[528px] tw-h-full tw-flex tw-gap-[24px]">
        <div className="tw-bg-white tw-w-[40px] tw-h-[40px] tw-px-[4px] tw-mt-[4px] tw-rounded-full tw-cursor-pointer tw-justify-center tw-items-center tw-hidden lg:tw-flex">
          <img src={close} alt="close" className="" onClick={handleClose} />
        </div>
        <div className="tw-bg-white tw-w-full tw-h-full tw-px-[32px] tw-py-[24px] tw-font-[Manrope] tw-text-[14px] placeholder:tw-text-[14px]">
          <div className="tw-font-bold tw-text-[24px] tw-text-[#0D6EFD]">
            Add an Admin
          </div>
          <div className="tw-mt-[56px]">
            <form
              className="tw-flex tw-flex-col tw-gap-6"
              onSubmit={handleSubmit}
            >
              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                  placeholder="Enter full name"
                  className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2"
                />
                <span style={{ color: "red" }}>{errors.name}</span>
              </div>

              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Email Address</label>
                <input
                  type="text"
                  name="email"
                  onChange={handleInputChange}
                  placeholder="Enter email address"
                  className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2"
                />
                <span style={{ color: "red" }}>{errors.email}</span>
              </div>

              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={handleInputChange}
                  placeholder="Enter password"
                  className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2"
                />
                <span style={{ color: "red" }}>{errors.password}</span>
              </div>

              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Permission</label>
                <select className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2 tw-pr-2">
                  <option value="">Select permission</option>
                </select>
              </div>

              <button
                onClick={handleSubmit}
                className="tw-h-[42px] tw-cursor-pointer tw-bg-[#0D6EFD] tw-text-zinc-50 tw-border-0 tw-rounded-[6px] tw-mt-5"
              >
                Add as Admin
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditUser = ({
  openAddAdmin,
  token,
  savingApproval,
  fetchData,
  handleMessage,
  selectedUser,
}) => {
  const handleClose = () => {
    openAddAdmin();
  };

  const [formData, setFormData] = useState({
    name: selectedUser.name,
    email: selectedUser.email,
    password: selectedUser.password,
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        return value.length < 3
          ? "Name must be at least 3 characters long"
          : "";
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const key in formData) {
      const error = validateInput(key, formData[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    handleClose();
    savingApproval(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}api/edit-admin/${selectedUser.id}`,
        formData,
        config
      )
      .then((response) => {
        console.log(response);

        if (response.data.success === true) {
          savingApproval(false);
          console.log("Form submission successful!", response.data);
          handleMessage("success", "User edited successfully!");
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
        savingApproval(false);
        handleMessage("error", "Error editing User!");
      });
  };

  return (
    <div className="admin-overlay tw-w-full">
      <div
        className="tw-absolute tw-bg-[#0002] tw-top-0 tw-left-0 tw-w-full tw-h-full"
        onClick={handleClose}
      ></div>
      <div className="tw-absolute tw-right-0 tw-top-[200px] lg:tw-top-0 tw-w-full lg:tw-w-[528px] tw-h-full tw-flex tw-gap-[24px]">
        <div className="tw-bg-white tw-w-[40px] tw-h-[40px] tw-px-[4px] tw-mt-[4px] tw-rounded-full tw-cursor-pointer tw-justify-center tw-items-center tw-hidden lg:tw-flex">
          <img src={close} alt="close" className="" onClick={handleClose} />
        </div>
        <div className="tw-bg-white tw-w-full tw-h-full tw-px-[32px] tw-py-[24px] tw-font-[Manrope] tw-text-[14px] placeholder:tw-text-[14px]">
          <div className="tw-font-bold tw-text-[24px] tw-text-[#0D6EFD]">
            Edit Admin
          </div>
          <div className="tw-mt-[56px]">
            <form
              className="tw-flex tw-flex-col tw-gap-6"
              onSubmit={handleSubmit}
            >
              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={handleInputChange}
                  value={formData.name}
                  placeholder="Enter full name"
                  className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2"
                />
                <span style={{ color: "red" }}>{errors.name}</span>
              </div>

              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Email Address</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter email address"
                  readOnly
                  className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2"
                />
                <span style={{ color: "red" }}>{errors.email}</span>
              </div>

              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={handleInputChange}
                  placeholder="Enter password"
                  className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2"
                />
                <span style={{ color: "red" }}>{errors.password}</span>
              </div>

              <div className="tw-flex tw-flex-col">
                <label className="tw-text-[#6D7A98]">Permission</label>
                <select className="tw-h-[42px] tw-bg-[#F7F9FB] tw-border-0 tw-mt-2 tw-pl-2 tw-pr-2">
                  <option value="">Select permission</option>
                </select>
              </div>

              <button
                onClick={handleSubmit}
                className="tw-h-[42px] tw-cursor-pointer tw-bg-[#0D6EFD] tw-text-zinc-50 tw-border-0 tw-rounded-[6px] tw-mt-5"
              >
                Edit Admin
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteConfirmationModal = ({ openDeleteAdmin, onDelete }) => (
  <div className="message-overlay" onClick={openDeleteAdmin}>
    <div className="delete-modal">
      <div className="headwarning">
        <h2 className="deletemodalhtwo">Warning</h2>

        <p className="deleteX tw-cursor-pointer" onClick={openDeleteAdmin}>
          x
        </p>
      </div>

      <p>Are you sure you want to delete this user?</p>
      <button className="cancel" onClick={openDeleteAdmin}>
        Cancel
      </button>
      <button className="delete tw-bg-red-900" onClick={onDelete}>
        Delete
      </button>
    </div>
  </div>
);

export default Adminmanagement;
