import React from "react";
import "./dashboard.css";
import cert from "./images/cert.png";
import book from "./images/book.png";
import user from "./images/user.png";
import courses from "../../images/front/courses.svg";
import students from "../../images/front/students.svg";
import scholarship from "../../images/front/scholarship.svg";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const COLORS = ["#67DDE4", "#FFD04D", "#8273D9", "#F3A358"];
const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const data02 = [
  { name: "A1", value: 80 },
  { name: "A2", value: 70 },
  { name: "B1", value: 50 },
  { name: "B2", value: 50 },
];

const Dashboard = () => {
  return (
    <div className="tw-mt-4 tw-pt-12 lg:tw-pt-5">
      <div className="tw-flex tw-justify-between tw-align-center tw-mb-4 tw-mt-3 lg:tw-w-full tw-w-[90%] tw-px-[5%] lg:tw-px-[0%]">
        <div className="uaa-subheader tw-self-center tw-mt-6 tw-text-gray-400 tw-text-[14px] tw-font-['Manrope']">
          Overall students performance chart
        </div>
      </div>

      <div className="tw-w-full lg:tw-w-full lg:tw-px-[0%] tw-pl-[5%] tw-overflow-x-hidden">
        <div className="tw-w-full tw-flex lg:tw-grid lg:tw-grid-cols-3 tw-justify-between tw-gap-[0.2rem] lg:tw-gap-[6rem] tw-overflow-x-scroll lg:tw-overflow-x-visible">
          <div className="tw-w-full tw-bg-[#e0ebfa80] tw-border-[#0d6efd] tw-py-[20px] tw-px-[22px] tw-rounded-[6px]">
            <div className="tw-flex tw-items-center tw-justify-between">
              <p>Number of Courses</p>
              <img src={courses} alt="dda" />
            </div>
            <h2 className="number">14</h2>
          </div>

          <div className="tw-w-full tw-bg-[#e4cf120d] tw-border-[#e4cf12] tw-py-[20px] tw-px-[22px] tw-rounded-[6px]">
            <div className="tw-flex tw-items-center tw-justify-between">
              <p>Number of Students</p>
              <img src={students} alt="dda" />
            </div>
            <h2 className="number">100</h2>
          </div>

          <div className="tw-w-full tw-bg-[#ffd8d857] tw-border-[#c91919] tw-py-[20px] tw-px-[22px] tw-rounded-[6px]">
            <div className="tw-flex tw-items-center tw-justify-between">
              <p>Scholarship Students</p>
              <img src={scholarship} alt="dda" />
            </div>
            <h2 className="number">20</h2>
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-mt-[34px] tw-gap-x-6 ">
        {/* for graph */}
        <div className="tw-w-full tw-px-[5%] lg:tw-px-[0%] lg:tw-w-[150%] tw-mb-[100px] ">
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
            <div className="tw-text-blue-600 tw-text-xl tw-font-medium tw-font-['Manrope'] tw-leading-normal">
              Learner's Insight
            </div>

            <select
              name="cars"
              id="cars"
              className="tw-h-[25.77px] tw-bg-slate-100 tw-rounded"
            >
              <option value="volvo">monthly</option>
              <option value="saab">yearly</option>
              <option value="mercedes">quarterly</option>
              <option value="audi">mininal</option>
            </select>
          </div>
          <div className="tw-text-gray-400 tw-text-base tw-font-medium tw-font-['Karla']  tw-mb-[10px]">
            Jul - Aug
          </div>
          <div
            className="tw-bg-white tw-rounded-lg tw-border tw-border-slate-200 tw-h-full tw-px-[11px] tw-py-[12px]"
            style={{ border: "1px solid #DDE5EE" }}
          >
            <div className="tw-h-[300px] tw-w-[100%]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={400}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        {/* for activity breakdown */}
        <div className="tw-w-full tw-px-[5%] lg:tw-w-full lg:tw-px-[0%]">
          <div className="tw-text-blue-600 tw-text-xl tw-font-medium tw-font-['Manrope'] tw-leading-normal tw-mb-8">
            Activity Breakdown
          </div>

          <div className="tw-w-full h-[99px] tw-bg-white tw-rounded-[10px] tw-flex tw-justify-between tw-items-center tw-shadow tw-border tw-border-indigo-300 tw-px-[15px] tw-py-[16px] tw-mb-2">
            <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-[20px] tw-w-[70%]">
              <img src={user} alt="" />
              <div className="tw-text-stone-500 tw-text-sm tw-font-['Roboto'] tw-leading-tight">
                Students Course Completion Rate
              </div>
            </div>

            <div className="tw-w-[10%] tw-text-right tw-text-gray-950 lg:tw-text-xl tw-text-sm tw-font-medium tw-font-['Manrope'] ">
              96%
            </div>
          </div>

          <div className="tw-w-full h-[99px] tw-bg-white tw-rounded-[10px] tw-flex tw-justify-between tw-items-center tw-shadow tw-border tw-border-indigo-300 tw-px-[15px] tw-py-[16px] tw-mb-2">
            <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-[20px] tw-w-[70%]">
              <img src={book} alt="" />
              <div className="tw-text-stone-500 tw-text-sm tw-font-['Roboto'] tw-leading-tight">
                Top Courses Taken
              </div>
            </div>

            <div className="tw-w-[10%] tw-text-right tw-text-gray-950 lg:tw-text-xl tw-text-sm tw-font-medium tw-font-['Manrope'] ">
              4%
            </div>
          </div>

          <div className="tw-w-full h-[99px] tw-bg-white tw-rounded-[10px] tw-flex tw-justify-between tw-items-center tw-shadow tw-border tw-border-indigo-300 tw-px-[15px] tw-py-[16px] tw-mb-2">
            <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-[20px] tw-w-[70%]">
              <img src={cert} alt="" />
              <div className="tw-text-stone-500 tw-text-sm tw-font-['Roboto'] tw-leading-tight">
                Certificate Issued
              </div>
            </div>

            <div className="tw-w-[10%] tw-text-right tw-text-gray-950 lg:tw-text-xl tw-text-sm tw-font-medium tw-font-['Manrope'] ">
              50
            </div>
          </div>
        </div>
      </div>
      {/* Top student top courses */}

      <div className="tw-w-full tw-px-[5%] lg:tw-w-full lg:tw-px-[0%] tw-flex tw-flex-col-reverse lg:tw-flex-row tw-gap-x-3 tw-mb-[200px] tw-mt-[34px]">
        <div className="lg:tw-w-[140%] tw-w-full ">
          <div
            className="tw-bg-white tw-rounded-lg tw-border tw-border-slate-200 tw-h-full tw-px-[11px] tw-py-[12px]"
            style={{ border: "1px solid #DDE5EE" }}
          >
            <div className="tw-text-blue-600 tw-text-xl tw-font-medium tw-font-['Manrope'] tw-leading-normal">
              Top Students
            </div>
            <table className="tw-w-full tw-mt-3 adjust-tr tw-border-collapse tw-text-[14px] lg:tw-text-[16px]">
              <thead>
                <tr className="tw-w-full tw-bg-[#F7F9FB] tw-py-4 tw-px-4 tw-h-[48px] tw-text-left tw-border-collapse tw-text-gray-400 tw-text-xs tw-font-semibold tw-font-['Manrope'] tw-leading-none">
                  <th>Name</th>
                  <th>Course</th>
                  <th>Completion date </th>
                  <th>Enrolled Date</th>
                </tr>
              </thead>

              <tr className="tw-w-full tw-h-[64px]">
                <td>Glory</td>
                <td>Web Development</td>
                <td>__________</td>
                <td>Aug, 10th 2023.</td>
              </tr>

              <tr className="tw-w-full tw-h-[64px]">
                <td>Glory</td>
                <td>Web Development</td>
                <td>__________</td>
                <td>Aug, 10th 2023.</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="tw-w-full lg:tw-w-full tw-bg-white tw-rounded-lg tw-border tw-border-slate-200 tw-mb-12">
          <div
            className="tw-bg-white tw-rounded-lg tw-border tw-border-slate-200 tw-h-full tw-px-[11px] tw-py-[12px]"
            style={{ border: "1px solid #DDE5EE" }}
          >
            <div className="tw-text-blue-600 tw-text-xl tw-font-medium tw-font-['Manrope'] tw-leading-normal">
              Top Courses
            </div>
            <div className="tw-w-full tw-flex tw-justify-center ">
              <PieChart width={200} height={200}>
                <Pie
                  data={data02}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={90}
                  fill="#82ca9d"
                >
                  {data.map((entry, index) => (
                    <Cell key={entry.name} fill={COLORS[index]} />
                  ))}
                </Pie>
              </PieChart>
            </div>

            <div className="tw-mt-[20px]">
              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-3">
                  <div className="tw-w-4 tw-h-4 tw-bg-teal-300 tw-rounded" />
                  <div>UI/UX Design</div>
                </div>

                <div className="tw-w-[62.43px] tw-text-right tw-text-gray-950 tw-text-xl tw-font-semibold tw-font-['Manrope'] ">
                  80%
                </div>
              </div>

              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-3">
                  <div className="tw-w-4 tw-h-4 tw-bg-amber-300 tw-rounded" />
                  <div>Cyber Security</div>
                </div>

                <div className="tw-w-[62.43px] tw-text-right tw-text-gray-950 tw-text-xl tw-font-semibold tw-font-['Manrope'] ">
                  70%
                </div>
              </div>

              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-3">
                  <div className="tw-w-4 tw-h-4 tw-bg-violet-500 tw-rounded" />
                  <div>Data Science</div>
                </div>

                <div className="tw-w-[62.43px] tw-text-right tw-text-gray-950 tw-text-xl tw-font-semibold tw-font-['Manrope'] ">
                  50%
                </div>
              </div>

              <div className="tw-flex tw-justify-between tw-items-center">
                <div className="tw-flex tw-justify-between tw-items-center tw-gap-x-3">
                  <div className="tw-w-4 tw-h-4 tw-bg-orange-400 tw-rounded" />
                  <div>Drone Tech</div>
                </div>

                <div className="tw-w-[62.43px] tw-text-right tw-text-gray-950 tw-text-xl tw-font-semibold tw-font-['Manrope'] ">
                  50%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
