import React from "react";
import instructor_cyril from "../../assets/images/instructors/instructor_cyril.png";
import instructor_helen from "../../assets/images/instructors/instructor_helen.png";
import instructor_mahmoud from "../../assets/images/instructors/instructor_mahmoud.png";
import instructor_sandra from "../../assets/images/instructors/instructor_sandra.png";

const Instructor = () => {
  return (
    <section className="instructor-area section-pt-120 section-pb-70">
      <div className="container">
        <div className="section__title-wrap">
          <div className="row align-items-center gap-4 gap-md-0">
            <div
              className="col-md-8"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <div className="section__title text-center text-md-start">
                <span className="sub-title">Our Qualified People Matter</span>
                <h2 className="title tg-svg">
                  Top{" "}
                  <span className="position-relative">
                    <span
                      className="svg-icon"
                      id="svg-8"
                      data-svg-icon="assets/img/icons/title_shape.svg"
                    ></span>
                    Class
                  </span>{" "}
                  instructors
                </h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="tg-button-wrap justify-content-center justify-content-md-end">
                <a href="/all-instructors" className="btn tg-svg">
                  <span className="text">All Instructors</span>{" "}
                  <span
                    className="svg-icon"
                    id="instructor-btn"
                    data-svg-icon="assets/img/icons/btn-arrow.svg"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-sm-6">
            <div
              className="instructor__item"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="instructor__img">
                <div
                  className="instructor__shape"
                  style={{
                    backgroundColor: "#E2FAFF",
                    borderRadius: "135px 0 0 0",
                  }}
                ></div>
                <a href="/#">
                  <img src={instructor_mahmoud} alt="instructor" />
                </a>
              </div>
              <div className="instructor__content">
                <div className="left">
                  <span className="designation">Blockchain Technology</span>
                  <h4 className="name">
                    <a href="/#">Mahmud Oloyede</a>
                  </h4>
                </div>
                <div className="right">
                  <span className="share">
                    <i className="flaticon-share"></i>
                  </span>
                  <ul className="social-list list-wrap">
                    <li>
                      <a href="#/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6">
            <div
              className="instructor__item"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="instructor__img">
                <div
                  className="instructor__shape"
                  style={{
                    backgroundColor: "#E4FFE2",
                    borderRadius: "135px 115px 115px 0",
                  }}
                ></div>
                <a href="/#">
                  <img src={instructor_cyril} alt="instructor" />
                </a>
              </div>
              <div className="instructor__content">
                <div className="left">
                  <span className="designation">Web Development</span>
                  <h4 className="name">
                    <a href="/#">ThankGod Cyril</a>
                  </h4>
                </div>
                <div className="right">
                  <span className="share">
                    <i className="flaticon-share"></i>
                  </span>
                  <ul className="social-list list-wrap">
                    <li>
                      <a href="#/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6">
            <div
              className="instructor__item"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <div className="instructor__img">
                <div
                  className="instructor__shape"
                  style={{
                    backgroundColor: "#E8EDFF",
                    borderRadius: "135px 0 0 0",
                  }}
                ></div>
                <a href="/#">
                  <img src={instructor_sandra} alt="instructor" />
                </a>
              </div>
              <div className="instructor__content">
                <div className="left">
                  <span className="designation">Data Analytics</span>
                  <h4 className="name">
                    <a href="/#">Sandra Mahanan</a>
                  </h4>
                </div>
                <div className="right">
                  <span className="share">
                    <i className="flaticon-share"></i>
                  </span>
                  <ul className="social-list list-wrap">
                    <li>
                      <a href="#/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-sm-6">
            <div
              className="instructor__item"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              <div className="instructor__img">
                <div
                  className="instructor__shape"
                  style={{
                    backgroundColor: "#FFE2E2",
                    borderRadius: "135px 115px 115px 0",
                  }}
                ></div>
                <a href="/#">
                  <img src={instructor_helen} alt="instructor" />
                </a>
              </div>
              <div className="instructor__content">
                <div className="left">
                  <span className="designation">UI & UX Design</span>
                  <h4 className="name">
                    <a href="/#">Helen Nnamani</a>
                  </h4>
                </div>
                <div className="right">
                  <span className="share">
                    <i className="flaticon-share"></i>
                  </span>
                  <ul className="social-list list-wrap">
                    <li>
                      <a href="#/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#/">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Instructor;
