import React, { useEffect, useState } from "react";
import filestorage from "../scholarship/images/file-storage 1.png";
import { Link, Outlet, useLocation } from "react-router-dom";
import axios from "axios";
import "../courses/cssforcourses/courses.css";

const Student = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  useEffect(() => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/all-students`;

    axios
      .get(endpointUrl)
      .then((response) => {
        console.log(response.data.data);
        setData(response.data.data);
        setFilteredData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const toggleOptionsVisibility = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null); // Hide options if the same item is clicked again
    } else {
      setSelectedItem(item); // Show options for the clicked item
    }
  };

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setDeleteModalVisibility(true);
  };

  const closeDeleteModal = () => {
    setSelectedItem(null);
    setDeleteModalVisibility(false);
  };

  const handleDelete = () => {
    // Implement your delete logic here
    console.log("Deleting course:", selectedItem.title);
    // Close the modal after deletion
    closeDeleteModal();
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredData = data.filter((item) =>
      Object.values(item).join(" ").toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredData);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return location.pathname !== "/student" ? (
    <Outlet />
  ) : (
    <div className="uaa-container lg:tw-w-full tw-px-[5%] lg:tw-px-[0%]">
      <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center tw-mb-4 tw-mt-12 lg:tw-mt-10 tw-px-2 sm:tw-px-0">
        <div className="tw-text-neutral-950 tw-text-xl tw-font-bold tw-font-['Manrope'] tw-leading-normal tw-tracking-tight">
          Student List
        </div>
        <input
          type="text"
          placeholder="Search by name..."
          value={searchTerm}
          onChange={handleSearch}
          className="tw-w-[100%] lg:tw-w-[384px] tw-border-[#D7DCE0] tw-rounded-[6px] tw-h-[35px] tw-pl-[10px] tw-text-[16px]"
        />
      </div>
      <div className="tw-w-full tw-overflow-x-hidden">
        <div className="tw-w-full tw-overflow-x-scroll">
          <table className="table tw-mt-[50px] tw-border-collapse tw-w-[1000px]">
            <thead>
              <tr>
                <th>Name</th>
                <th>Course</th>
                <th>Completion Rate</th>
                <th>Admission Type</th>
                <th>Student ID</th>
                <th>Issue Certificate</th>
                <th>Enrolled Date</th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr className="tw-w-[70vw] tw-h-[50vh] tw-flex tw-items-center tw-justify-center">
                  <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
                    <div className="filenotindiv">
                      <img src={filestorage} alt="" />
                      <h3>No record yet</h3>
                    </div>
                  </div>
                </tr>
              ) : (
                <>
                  {currentData.map((student, index) => (
                    <tr key={index + 1}>
                      <td>{student.name}</td>
                      <td>{student.course}</td>
                      <td>
                        <div className="tw-flex tw-items-center tw-gap-x-2">
                          <div className="tw-bg-gradient-to-b tw-from-[#98bdf3] tw-to-[#FFFFFFB2] tw-rounded-[10px] tw-w-[75px] tw-h-[5px]">
                            <div className="tw-bg-[#059302] tw-w-[80%] tw-h-full tw-rounded-[10px]"></div>
                          </div>
                          <div>{student.completion_rate}</div>
                        </div>
                      </td>
                      <td>{student.admission_type}</td>
                      <td>{student.student_id}</td>
                      <td>
                        <button
                          style={{ border: "1px solid #6AA4FA" }}
                          className="tw-text-[#6AA4FA] tw-bg-transparent tw-cursor-pointer tw-h-[36px] tw-rounded-[6px]"
                        >
                          In Progress
                        </button>
                      </td>
                      <td className="lasttable">
                        {student.enrolled_date} |{" "}
                        <span className="">12:38 PM</span>
                        <span
                          className="threestoke"
                          onClick={() => toggleOptionsVisibility(1)}
                        >
                          ⋮
                        </span>
                        {selectedItem === 1 && (
                          <div className="options">
                            <span className="option">
                              <Link
                                className="optioncourse"
                                onClick={() => openDeleteModal(1)}
                              >
                                Delete
                              </Link>
                            </span>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <div>
            <h2>
              Showing {""}
              <span>
                Page {currentPage} of {totalPages}
              </span>
            </h2>
          </div>
          <div className="paginationbutton">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <div className="current_page">{currentPage}</div>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {isDeleteModalVisible && (
        <DeleteConfirmationModal
          onClose={closeDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

const DeleteConfirmationModal = ({ onClose, onDelete }) => (
  <div className="modal-overlay" onClick={onClose}>
    <div className="delete-modal">
      <div className="headwarning">
        <h2 className="deletemodalhtwo">Warning</h2>

        <p className="deleteX" onClick={onClose}>
          x
        </p>
      </div>

      <p>Are you sure you want to delete this student?</p>
      <button className="cancel" onClick={onClose}>
        Cancel
      </button>
      <button className="delete" onClick={onDelete}>
        Delete
      </button>
    </div>
  </div>
);

export default Student;
