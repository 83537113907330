import React, { useState, useRef } from "react";
import axios from "axios";
import { useAuth } from "../auth/Auth";
import linkImage from "../../images/common/link.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { QuizSettingMenu } from "../common/QuizSettingMenu";

export const QuizSettings = () => {
  const [link, setLink] = useState("No Link generated!");
  const { token } = useAuth();
  const linkRef = useRef();

  const generateLink = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/generate-quiz-link`, config)
      .then((response) => {
        setLink(response.data.generated_link);
        toast.success("Quiz link generated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        console.error("Error generating link:", error);
        toast.error(error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  function copyToClipboard(e) {
    const set = linkRef.current.value;
    navigator.clipboard.writeText(set);
    e.target.focus();
    toast.success("Link Copied!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  return (
    <div className="uaa-container tw-px-[5%] lg:tw-px-0">
      <div className="tw-flex tw-justify-between tw-align-center tw-mb-4 tw-mt-3">
        <div className="uaa-subheader tw-self-center tw-mt-8">Active</div>
      </div>

      <QuizSettingMenu />

      <ToastContainer />
      <div className="generate-link-container">
        <div className="generate-link-all">
          <div className="gen-link-title">Generate Link</div>
          <button type="button" className="gen-link-btn" onClick={generateLink}>
            Generate Link
          </button>
          <div className="gen-link-output">
            <input
              type="text"
              value={link}
              className="gen-link-input"
              ref={linkRef}
              readOnly
              disabled
            />
            <div className="gen-link-copy" onClick={copyToClipboard}>
              Copy <i className="fa-solid fa-link-simple"></i>
              <img
                src={linkImage}
                alt="link"
                style={{ width: "50px", height: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
