import React, { useState, useEffect } from "react";
import logo from "../../images/home/logo.png";
import diamond from "../../images/common/diamond.svg";
import QuizComponent from "./QuizComponent";
import "../../admin/css/quiz.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const Quiz = () => {
  const [starttest, setStarttest] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [validAccessCode, setValidAccessCode] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const code = searchParams.get("access_code");
    setAccessCode(code || "");

    // Check access code validity
    if (code) {
      axios
        .post(`${baseUrl}api/check-quiz-code`, {
          access_code: code,
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            setQuizData(data.data);
            setValidAccessCode(true);
          } else {
            setValidAccessCode(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    } else {
      setValidAccessCode(false);
      setLoading(false);
    }
  }, [searchParams]);

  const handlestarttest = () => {
    setStarttest(true);
  };

  if (loading) {
    return <div className="tw-mt-5 tw-text-center">Loading...</div>;
  }

  if (error) {
    return (
      <div id="oopss">
        <div id="error-text">
          <img
            src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
            alt="404"
          />
          <span>404 PAGE</span>
          <p class="p-a"> Access code not found </p>
          <p class="p-b">... Back to Uniconacademy</p>
          <a href="./" class="back">
            ... Back to Home page
          </a>
        </div>
      </div>
    );
  }

  if (!validAccessCode) {
    return (
      <div id="oopss">
        <div id="error-text">
          <img
            src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
            alt="404"
          />
          <span>404 PAGE</span>
          <p class="p-a">Access code not found </p>
          <p class="p-b">... Back to Uniconacademy</p>
          <a href="./" class="back">
            ... Back to Home page
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <nav className="navquiz">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </nav>

        <div className="quiz-diamond">
          <img src={diamond} alt="Diamond" />
        </div>

        {starttest ? (
          <QuizComponent data={quizData} /> // Pass fetched quiz data to QuizComponent
        ) : (
          <form>
            <div className="quiz-cover">
              <div className="quiz-page">
                <div className="quiz-header">
                  <div className="quiz-title">Hi, Welcome!</div>
                  <div className="quiz-subtile">
                    Please provide your details to start quiz{" "}
                  </div>
                </div>

                <div className="form-quiz-cont">
                  Instructions: Read carefully
                </div>

                <ul className="form-quiz-instruc">
                  <li>You have one hour to complete you quiz.</li>
                  <li>Each Questions expires in 5mins</li>
                  <li>Make sure you pay attentive before attempt questions</li>
                  <li>
                    When you start quiz you can not close to continue later
                  </li>
                </ul>

                <button onClick={handlestarttest} className="quiz-btn">
                  {" "}
                  Start Quiz{" "}
                </button>
              </div>
            </div>
          </form>
        )}
        <div className="quiz-diamond-two">
          <img src={diamond} alt="Diamond" />
        </div>
      </div>
    </>
  );
};

export default Quiz;
