import { useState } from "react";
import caret_up from "../../images/common/caret_up.svg";
import caret_down from "../../images/common/caret_down.svg";

const SingleAccordion = ({ header, body }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleItemClick = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div key={1}>
      <div
        className="tw-flex tw-mb-[5px] tw-text-[14px] tw-bg-[#F7F9FB] tw-justify-between tw-items-center tw-cursor-pointer tw-p-[10px] tw-text-[#010F0F]"
        onClick={() => handleItemClick(1)}
      >
        {header}
        <div>
          <img
            src={1 === expandedIndex ? caret_up : caret_down}
            alt="caret-left"
            className="tw-w-[22px] tw-h-[22px]"
          />
        </div>
      </div>
      {1 === expandedIndex && (
        <div
          style={{
            padding: "10px",
            border: "1px solid #ddd",
            borderTop: "none",
            marginBottom: "3px",
          }}
        >
          <p>{body}</p>
        </div>
      )}
    </div>
  );
};

export default SingleAccordion;
