import React from "react";
import left from "../../images/common/left.svg";
import { Link } from "react-router-dom";

const ViewQuizQuestions = () => {
  return (
    <div className="uaa-container">
      <div className="tw-flex tw-justify-between tw-align-center tw-mb-4 tw-mt-3">
        <div className="uaa-subheader tw-self-center tw-mt-6">
          Verbal Reasoning
        </div>
      </div>

      <Link className="uaa-title-image" to="/admin/add-quiz-questions">
        <img src={left} alt="left" />
        <div className="">Back</div>
      </Link>
      <div className="tw-flex tw-justify-between tw-mt-5">
        <div className="">
          <div className="saved-questions">Saved Questions</div>
          <div className="saved-questions-no tw-mt-2">12 Questions</div>
        </div>
        <div className="">
          <div className="add-quiz-btn">+ Add Questions</div>
        </div>
      </div>

      <div>
        <table className="table-quiz">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>What country has the tallest building? </td>
              <td>
                <Link>View</Link>
              </td>
            </tr>
            <tr>
              <td>What country has the tallest building? </td>
              <td>
                <Link>View</Link>
              </td>
            </tr>
            <tr>
              <td>What country has the tallest building? </td>
              <td>
                <Link>View</Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewQuizQuestions;
