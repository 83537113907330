import { useState } from "react";
import caret_up from "../../images/common/caret_up.svg";
import caret_down from "../../images/common/caret_down.svg";

const Accordion = ({ items, handleRemove }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleItemClick = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {items.map((item, index) => (
        <div key={index + 1}>
          <div
            className="tw-flex tw-mb-[5px] tw-text-[14px] tw-bg-[#F7F9FB] tw-justify-between tw-items-center tw-cursor-pointer tw-p-[10px] tw-text-[#010F0F]"
            onClick={() => handleItemClick(index)}
          >
            <button
              onClick={handleRemove}
              className="tw-w-[22px] tw-h-[22px] tw-border-0 tw-bg-[#fefefe] tw-mr-2 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-text-[#782525] tw-text-[17px]"
            >
              x
            </button>
            {item.topic}
            <div>
              <img
                src={index === expandedIndex ? caret_up : caret_down}
                alt="caret-left"
                className="tw-w-[22px] tw-h-[22px]"
              />
            </div>
          </div>
          {index === expandedIndex && (
            <div
              style={{
                padding: "10px",
                border: "1px solid #ddd",
                borderTop: "none",
                marginBottom: "3px",
              }}
            >
              {item.subtopics.map((subtopic) => (
                <ul>
                  <li>{subtopic}</li>
                </ul>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
