import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import "../organizationsetting/organizationsetting.css";

const Organizationsetting = () => {
  const location = useLocation();

  return location.pathname !== "/admin/organization-setting" ? (
    <Outlet />
  ) : (
    <div>
      <div className="tw-flex tw-justify-between tw-align-center tw-mb-4 tw-mt-12 "></div>

      <div className="tw-w-[90%] lg:tw-w-full tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2  tw-grid-cols-1 tw-gap-4 tw-mb-2 tw-mt-12 tw-px-[5%] lg:tw-px-[0%] tw-text-gray-400 tw-text-sm tw-font-['Manrope']">
        <div className="tw-flex tw-flex-col">
          <label htmlFor="">Name</label>
          <input
            className="inputorganization"
            type="text"
            placeholder="UNICCON Academy"
          />
        </div>
        <div className="tw-flex tw-flex-col">
          <label htmlFor="">Phone Number</label>
          <input
            className="inputorganization"
            type="text"
            placeholder="+234 703 321 8457"
          />
        </div>
        <div className="tw-flex tw-flex-col">
          <label htmlFor="">Email Address</label>
          <input
            className="inputorganization"
            type="text"
            placeholder="info@uniccon.com"
          />
        </div>
        <div className="tw-flex tw-flex-col">
          <label htmlFor="">Dated Created</label>
          <input
            className="inputorganization"
            type="text"
            placeholder="July 20, 2021 "
          />
        </div>
        <div className="tw-flex tw-flex-col">
          <label htmlFor="">Country</label>
          <input
            className="inputorganization"
            type="text"
            placeholder="Nigeria"
          />
        </div>
        <div className="tw-flex tw-flex-col">
          <label htmlFor="">Status</label>
          <input
            className="inputorganization"
            type="text"
            placeholder="Active"
          />
        </div>
      </div>
    </div>
  );
};

export default Organizationsetting;
