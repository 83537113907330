import React from "react";
import { Link, useLocation } from "react-router-dom";

export const QuizSettingMenu = () => {
  const location = useLocation();

  return (
    <>
      <div className="quiz-setting-all tw-w-[94%]">
        <Link
          to="/admin/quiz-scores"
          className={`text-decoration-none  ${
            location.pathname === "/admin/quiz-scores"
              ? "tw-text-blue-700"
              : "tw-text-gray-400"
          }`}
        >
          <div
            className={`quiz-setting-header ${
              location.pathname === "/admin/quiz-scores"
                ? "quiz-setting-header-active"
                : ""
            } `}
          >
            Quiz Scores
          </div>
        </Link>
        <Link
          to="/admin/add-quiz-questions"
          className={`text-decoration-none ${
            location.pathname === "/admin/add-quiz-questions"
              ? "tw-text-blue-700"
              : "tw-text-gray-400"
          }`}
        >
          <div
            className={`quiz-setting-header ${
              location.pathname === "/admin/add-quiz-questions"
                ? "quiz-setting-header-active"
                : ""
            } `}
          >
            Add Quiz Questions
          </div>
        </Link>
        <Link
          to="/admin/quiz-settings"
          className={`text-decoration-none ${
            location.pathname === "/admin/quiz-settings"
              ? "tw-text-blue-700"
              : "tw-text-gray-400"
          }`}
        >
          <div
            className={`quiz-setting-header ${
              location.pathname === "/admin/quiz-settings"
                ? "quiz-setting-header-active"
                : ""
            } `}
          >
            Quiz Settings
          </div>
        </Link>
      </div>
    </>
  );
};
