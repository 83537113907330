import logo from "../assets/logo_white.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer-bg common-color-dark">
      <div className="footer__top-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="footer-widget">
                <div className="footer__about">
                  <div className="footer__logo logo">
                    <a href="/">
                      <img
                        src={logo}
                        style={{ width: "120px", height: "60px" }}
                        alt="img"
                      />
                    </a>
                  </div>
                  <p>
                    Uniccon Academy leverages industry experience & expertise to
                    create industry relevant curriculum alongside practical
                    applications for trainees to thrive in the professional
                    world.
                  </p>
                  <ul className="list-wrap m-0 p-0">
                    <li className="address">
                      3rd Floor, Lanre Shittu House, Mabushi, Abuja. 900108
                    </li>
                    <li className="number">+2349131574290</li>
                    <li className="socials">
                      <a href="https://www.facebook.com/unicconacademy">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="https://twitter.com/UnicconAcademy">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="https://www.instagram.com/unicconacademy/">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="https://www.linkedin.com/company/unicconacademy/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                      <a href="https://www.youtube.com/@UNICCONAcademy">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="footer-widget widget_nav_menu">
                <h4 className="fw-title">Resources</h4>
                <ul className="list-wrap">
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="/pricing">Our Courses</a>
                  </li>
                  <li>
                    <a href="https://chuksekwuemefoundation.org/apply-for-scholarship">
                      Scholarship
                    </a>
                  </li>
                  <li>
                    <a href="#/">Conditions</a>
                  </li>
                  <li>
                    <a href="#/">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="footer-widget widget_nav_menu">
                <h4 className="fw-title">Courses</h4>
                <ul className="list-wrap">
                  <li>
                    <a href="/pricing">Web Development</a>
                  </li>
                  <li>
                    <a href="/pricing">UI/UX Design</a>
                  </li>
                  <li>
                    <a href="/pricing">Mobile App Development</a>
                  </li>
                  <li>
                    <a href="/pricing">AI and Machine Learning</a>
                  </li>
                  <li>
                    <a href="/pricing">Graphics Design</a>
                  </li>
                  <li>
                    <a href="/pricing">Cyber Security</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="footer-widget">
                <h4 className="fw-title">Working Hours</h4>
                <div className="footer__working-list">
                  <div className="footer__working-item">
                    <span className="day">Mon - Fri</span>
                    <span className="time">8:00 AM - 5:00 PM</span>
                  </div>
                  <div className="footer__working-item">
                    <span className="day">Mon - Fri</span>
                    <span className="time">8:45 AM - 12:30 PM</span>
                  </div>
                  <div className="footer__working-item">
                    <span className="day">Mon - Fri</span>
                    <span className="time">2:00 PM - 5:00 PM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright__wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="copyright__text">
                <p>
                  Copyright © {currentYear} Uniccon Academy. All rights
                  reserved.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="copyright__menu">
                <ul className="list-wrap d-flex flex-wrap justify-content-center justify-content-lg-end">
                  <li>
                    <a href="#/">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#/">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
