import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, Navigate, useLocation } from "react-router-dom";
import "./cssforcourses/view.css";
import AddTopic from "./common/AddTopic";
import Accordion from "../common/Accordion";
import { useAuth } from "../auth/Auth";
import SingleAccordion from "../common/SingleAccordion";

const AddCourse = () => {
  const { token } = useAuth();

  const [activeTab, setActiveTab] = useState("firstTab");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const location = useLocation();

  console.log(location);

  return (
    <>
      {location.state != null ? (
        <>
          <div className="tw-flex tw-items-center tw-justify-between tw-w-[90%] tw-px-[5%] lg:tw-px-[0%] lg:tw-w-full tw-mt-20">
            <Link to={`/courses`} className="btnback">
              <i className="fa fa-chevron-left" aria-hidden="true"></i> Back
            </Link>
          </div>

          <div
            style={{ border: "1px solid #EAEDF4" }}
            className="tw-border-2 border-solid tw-border-[#EAEDF4] lg:gap-x-[4%] tw-rounded-[8px] tw-h-auto tw-flex-col lg:tw-flex-row tw-mt-[30px] tw-pt-[41px] tw-flex tw-justify-between tw-w-[90%] tw-px-[5%] lg:tw-px-[5%] tw-py-[20px] tw-pb-[200px] lg:tw-pb-[20px]"
          >
            <div className="tw-flex tw-flex-col tw-mb-8 lg:tw-w-[48%]">
              <label className="labelito tw-mb-1 " htmlFor="input1">
                Course Title
              </label>
              <input
                className="academy-admin-input"
                type="text"
                id="input1"
                name="title"
                value={location.state.title}
              />

              <label className="labelito tw-mb-1" htmlFor="input2">
                Course Duration
              </label>
              <input
                type="text"
                id="input2"
                name="duration"
                className="academy-admin-input"
                value={location.state.duration}
              />

              <label className="labelito tw-mb-1" htmlFor="input3">
                Physical Contact Per Week
              </label>
              <input
                type="text"
                id="input3"
                name="physical_contact"
                className="academy-admin-input"
                value={location.state.physical_contact}
              />

              <label className="labelito tw-mb-1" htmlFor="input4">
                Course Overview
              </label>
              <SingleAccordion
                header={"Overview"}
                body={location.state.description}
              />

              <label className="labelito tw-mb-1" htmlFor="input4">
                Course Image
              </label>
              <div className="upload tw-mt-1 tw-items-center">
                <div className="imgspot tw-overflow-hidden">
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="selected"
                      className="tw-max-h-[60px] tw-max-w-[100%]"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="tw-w-full tw-mb-8 lg:tw-w-[48%]">
              <label htmlFor="" className="labelito tw-mb-2">
                Select Course Type
              </label>
              <div className="StandardAdvancetab tw-mt-1 tw-w-full tw-mb-3">
                <div
                  className={`tab ${
                    activeTab === "firstTab" ? "activecoursetype" : ""
                  }`}
                  onClick={() => handleTabChange("firstTab")}
                >
                  Standard
                </div>
                <div
                  className={`tab ${
                    activeTab === "secondTab" ? "activecoursetype" : ""
                  }`}
                  onClick={() => handleTabChange("secondTab")}
                >
                  Advanced
                </div>
              </div>

              <div className="tab-content">
                {activeTab === "firstTab" && (
                  <div className="tw-flex tw-flex-col">
                    <label htmlFor="" className="labelito tw-mb-1 tw-mt-1">
                      Standard Price
                    </label>
                    <input
                      type="text"
                      className="academy-admin-input"
                      name="price"
                      value={location.state.price}
                    />
                    {/* 
                    <label className="labelito tw-mb-1">
                      What you'll learn
                    </label>
                    <Accordion items={formList} handleRemove={handleRemove} /> */}
                  </div>
                )}
                {activeTab === "secondTab" && (
                  <div className="tw-flex tw-flex-col">
                    <label htmlFor="" className="labelito tw-mb-1 tw-mt-1">
                      Advanced Price
                    </label>
                    <input
                      type="text"
                      className="academy-admin-input"
                      name="price"
                      value={location.state.price}
                    />

                    {/* <label className="labelito tw-mb-1">
                      What you'll learn
                    </label>
                     <Accordion
                      items={location.state.learning_paths}
                      handleRemove={handleRemove}
                    />  */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="tw-h-[200px] lg:tw-h-[20px]"></div>
        </>
      ) : (
        <Navigate to="/courses" />
      )}
    </>
  );
};

export default AddCourse;
