import PropTypes from "prop-types";
import banner_bg from "../assets/banner_bg.jpg";

const BreadCrumb = ({ name, location }) => {
  return (
    <section
      className="breadcrumb-area breadcrumb-bg"
      style={{ backgroundImage: `url(${banner_bg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb-content">
              <h3
                className="title"
                dangerouslySetInnerHTML={{ __html: name }}
              ></h3>
              <nav className="breadcrumb">
                <span>
                  <a href="index.html">Home</a>
                </span>
                <span className="breadcrumb-separator">
                  <i className="fas fa-angle-right"></i>
                </span>
                <span dangerouslySetInnerHTML={{ __html: location }}></span>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BreadCrumb.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
};

export default BreadCrumb;
