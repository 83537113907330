import React, { useState, useEffect } from "react";
import logo from "../../images/home/logo.png";
import diamond from "../../images/common/diamond.svg";
import QuizComponent from "./QuizComponent";
import "../../admin/css/quiz.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const MyQuiz = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [starttest, setStarttest] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [validAccessCode, setValidAccessCode] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get("access_code");
    setAccessCode(code || "");

    // Check access code validity
    if (code) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}api/verify-quiz-code`, {
          access_code: code,
        })
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          if (data.success) {
            console.log(data);
            setQuizData(data.data);
            setValidAccessCode(true);
            if (data.quiz_status === "in_progress") {
              //setStarttest(true);
              console.log("started");
            }
          } else {
            setValidAccessCode(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError(true);
          setLoading(false);
          console.error("Error fetching data:", error);
        });
    } else {
      setValidAccessCode(false);
      setLoading(false);
    }
  }, [searchParams]);

  const [formData, setFormData] = useState({
    access_code: accessCode,
    name: "",
    email: "",
    phone: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        return value.length < 3
          ? "Name must be at least 3 characters long"
          : "";
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address";
      case "phone":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Phone must be a number";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const key in formData) {
      const error = validateInput(key, formData[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    const code = searchParams.get("access_code");

    const modifiedFormData = {
      ...formData,
      access_code: code,
    };

    axios
      .post(`${baseUrl}api/start-quiz`, modifiedFormData)
      .then((response) => {
        if (response.data.success) {
          console.log("Form submission successful!", response.data);
          setStarttest(true);
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
      });
  };

  if (loading) {
    return <div className="tw-text-center tw-mt-5">Loading...</div>;
  }

  if (error) {
    return (
      <div id="oopss">
        <div id="error-text">
          <img
            src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
            alt="404"
          />
          <span>404 PAGE</span>
          <p className="p-a"> Access code not found </p>
          <p className="p-b">... Back to Uniconacademy</p>
          <a href="./" className="back">
            ... Back to Home page
          </a>
        </div>
      </div>
    );
  }

  if (!validAccessCode) {
    return (
      <div id="oopss">
        <div id="error-text">
          <img
            src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
            alt="404"
          />
          <span>404 PAGE</span>
          <p className="p-a">Access code not found </p>
          <p className="p-b">... Back to Uniconacademy</p>
          <a href="./" className="back">
            ... Back to Home page
          </a>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <nav className="navquiz">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </nav>

        <div className="quiz-diamond">
          <img src={diamond} alt="Diamond" />
        </div>

        {starttest ? (
          <QuizComponent data={quizData} /> // Pass fetched quiz data to QuizComponent
        ) : (
          <form>
            <div className="quiz-cover">
              <div className="quiz-page">
                <div className="quiz-header">
                  <div className="quiz-title">Take a Quiz</div>
                  <div className="quiz-subtile">
                    Please provide your details to start quiz{" "}
                  </div>
                </div>
                <input
                  value={formData.access_code}
                  name="access_code"
                  onChange={handleInputChange}
                  type="hidden"
                />

                <div className="form-quiz-hold">
                  <label className="quiz-form-label">Full Name</label>
                  <input
                    type="text"
                    className="quiz-form-input"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <span style={{ color: "red" }}>{errors.name}</span>
                </div>

                <div className="form-quiz-hold">
                  <label className="quiz-form-label">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    className="quiz-form-input"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <span style={{ color: "red" }}>{errors.email}</span>
                </div>

                <div className="form-quiz-hold">
                  <label className="quiz-form-label">Phone Number</label>
                  <input
                    type="tel"
                    name="phone"
                    className="quiz-form-input"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  <span style={{ color: "red" }}>{errors.phone}</span>
                </div>

                <button onClick={handleSubmit} className="quiz-btn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
        <div className="quiz-diamond-two">
          <img src={diamond} alt="Diamond" />
        </div>
      </div>
    </>
  );
};

export default MyQuiz;
