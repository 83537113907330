import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import "./cssforcourses/view.css";
import AddTopic from "./common/AddTopic";
import Accordion from "../common/Accordion";
import { useAuth } from "../auth/Auth";
import loading from "../../images/common/loading.svg";
import SavingStatus from "../common/SavingStatus";
import StatusMessage from "../common/StatusMessage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import AddFaq from "./common/AddFaq";

const EditCourse = () => {
  const { token } = useAuth();
  const [message, setMessage] = useState(false);
  const [saving, setSaving] = useState(false);
  const [save, setSave] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  const [loadingPage, setLoadingPage] = useState(true);
  const [advancedCourse, setAdvancedCourse] = useState([]);
  const [standardCourse, setStandardCourse] = useState([]);
  const [standardId, setStandardId] = useState("");
  const [advancedId, setAdvancedId] = useState("");
  const [editorData, setEditorData] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageForm, setImageForm] = useState(null);

  useEffect(() => {
    const data = {
      course_id: id,
    };

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/view-course`;

    axios
      .post(endpointUrl, data)
      .then((response) => {
        console.log("data", response.data.data);
        setStandardCourse(response.data.data.standard);
        setAdvancedCourse(response.data.data.advanced);
        setStandardId(response.data.data.standard.id);
        setAdvancedId(response.data.data.advanced.id);
        setSelectedImage(
          "https://apis.unicconacademy.com/storage/" +
            response.data.data.standard.image
        );
        setCourseDetails({
          title: response.data.data.standard.title || "",
          slug: response.data.data.standard.slug || "",
          description: response.data.data.standard.description || "",
          duration: response.data.data.standard.duration || "",
          physical_contact: response.data.data.standard.physical_contact || "",
          // image: null,
          standardPrice: response.data.data.standard.price || "",
          advancedPrice: response.data.data.advanced.price || "",
        });
        setEditorData(response.data.data.standard.description);
        const faqArray =
          response.data.data.standard.faq == null
            ? [{ topic: "", content: "" }]
            : JSON.parse(response.data.data.standard.faq || []);
        setFaq(faqArray);

        const standardSubtopics =
          response.data.data.standard.learning_paths.map((learningPath) => ({
            topic: learningPath.topic,
            subtopics: learningPath.subtopics.map(
              (subtopic) => subtopic.subtopic
            ),
          }));

        const advancedSubtopics =
          response.data.data.advanced.learning_paths.map((learningPath) => ({
            topic: learningPath.topic,
            subtopics: learningPath.subtopics.map(
              (subtopic) => subtopic.subtopic
            ),
          }));

        setStandardList(standardSubtopics);
        setAdvancedList(advancedSubtopics);

        setLoadingPage(false);
      })
      .catch((error) => {
        // if (response.status === 500) {
        //   window.location.href = "/not-found";
        // }
        console.error("Error:", error);
      });
  }, [id]);

  const initialCourseDetails = {
    id: "",
    title: "",
    slug: "",
    description: "",
    duration: "",
    physical_contact: "",
    image: null,
    standardPrice: "",
    advancedPrice: "",
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const [errors, setErrors] = useState({ initialCourseDetails });

  const [courseDetails, setCourseDetails] = useState({
    ...initialCourseDetails,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const handleMessage = (status, message) => {
    setMessage({
      status,
      message,
    });
  };

  const savingApproval = (status) => {
    setSaving(status);
  };

  useEffect(() => {
    if (standardCourse && save) {
      const course = {
        course_type: "Standard",
        courses: [
          {
            ...standardCourse,
          },
          {
            ...advancedCourse,
          },
        ],
      };
      console.log(course);
      handleSubmit(course);
    }
  }, [standardCourse, advancedCourse, save]);

  const validateInput = (name, value) => {
    switch (name) {
      case "title":
        return value.length < 3
          ? "Title must be at least 3 characters long"
          : "";
      case "duration":
        return value.length < 3
          ? "Duration must be at least 3 characters long"
          : "";
      case "standardPrice":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Price must be a number";
      case "advancedPrice":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Price must be a number";
      case "description":
        return value.length < 3
          ? "Description must be at least 3 characters long"
          : "";
      case "physical_contact":
        return value.length < 3
          ? "Physical contact must be at least 3 characters long"
          : "";
      default:
        return "";
    }
  };

  const [faq, setFaq] = useState([{ topic: "", content: "" }]);

  const handleSaveChanges = () => {
    for (const key in courseDetails) {
      const error = validateInput(key, courseDetails[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    setStandardCourse({
      id: standardId,
      title: courseDetails.title,
      slug: courseDetails.slug,
      description: editorData,
      price: courseDetails.standardPrice,
      duration: courseDetails.duration,
      physical_contact: courseDetails.physical_contact,
      image: null,
      faq: faq,
      learning_paths: standardList,
    });

    setAdvancedCourse({
      id: advancedId,
      title: courseDetails.title,
      slug: courseDetails.slug,
      description: editorData,
      price: courseDetails.advancedPrice,
      duration: courseDetails.duration,
      physical_contact: courseDetails.physical_contact,
      image: null,
      faq: faq,
      learning_paths: advancedList,
    });

    setSave(true);
  };

  const handleSubmit = (course) => {
    // console.log(selectedImage);

    savingApproval(true);
    setSave(false);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}api/edit-course/${id}`,
        course,
        config
      )
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          savingApproval(false);
          console.log("Form submission successful!", response.data);
          // setCourseDetails({ ...initialCourseDetails });
          // setStandardList([]);
          // setAdvancedList([]);
          // setEditorData("");
          if (imageForm) {
            const formData = new FormData();
            formData.append("image", imageForm);

            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };

            axios
              .post(
                `${process.env.REACT_APP_BASE_URL}api/courses/${id}/upload-image`,
                formData,
                config
              )
              .then((response) => {
                console.log("Upload successful:", response.data);
                handleMessage("success", "Course edited successfully!");
              })
              .catch((error) => {
                console.error("Error uploading file:", error);
              });
          } else {
            console.log("No file selected");
            handleMessage("success", "Course added successfully!");
          }
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
        savingApproval(false);
        handleMessage("error", "Error adding course!");
      });
  };

  const [activeTab, setActiveTab] = useState("firstTab");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [standardList, setStandardList] = useState([]);
  const [advancedList, setAdvancedList] = useState([]);

  const handleStandard = (formData) => {
    setStandardList([...standardList, formData]);
  };

  const handleAdvanced = (formData) => {
    setAdvancedList([...advancedList, formData]);
  };

  const handleRemove = (index) => {
    const updatedFormList = [...standardList];
    updatedFormList.splice(index, 1);
    setStandardList(updatedFormList);
  };

  const removeAdvanced = (index) => {
    const updatedFormList = [...advancedList];
    updatedFormList.splice(index, 1);
    setAdvancedList(updatedFormList);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    setImageForm(file);

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const editorConfig = {
    shouldNotGroupResize: true,
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-px-[5%] lg:tw-px-[0%] lg:tw-w-full tw-mt-20">
        <Link to={`/courses`} className="btnback">
          <i className="fa fa-chevron-left" aria-hidden="true"></i> Back
        </Link>
        <button className="academy-admin-btn" onClick={handleSaveChanges}>
          Save Changes
        </button>
      </div>
      <form encType="multipart/form-data">
        <div
          style={{ border: "1px solid #EAEDF4" }}
          className="tw-border-2 border-solid tw-border-[#EAEDF4] lg:gap-x-[4%] tw-rounded-[8px] tw-h-auto tw-flex-col lg:tw-flex-row tw-mt-[30px] tw-pt-[41px] tw-flex tw-justify-between tw-w-full tw-px-[5%] lg:tw-px-[5%] tw-py-[20px] tw-pb-[200px] lg:tw-pb-[20px]"
        >
          <div className="tw-flex tw-flex-col tw-mb-8 lg:tw-w-[48%]">
            <label className="labelito tw-mb-1 " htmlFor="input1">
              Course Title
            </label>
            <span style={{ color: "red" }}>{errors.title}</span>
            <input
              className="academy-admin-input"
              type="text"
              id="input1"
              name="title"
              value={courseDetails.title}
              onChange={handleInputChange}
            />

            <label className="labelito tw-mb-1 " htmlFor="slug">
              Course Slug
            </label>
            <span style={{ color: "red" }}>{errors.title}</span>
            <input
              className="academy-admin-input"
              type="text"
              id="slug"
              name="slug"
              value={courseDetails.slug}
              onChange={handleInputChange}
            />

            <label className="labelito tw-mb-1" htmlFor="input2">
              Course Duration
            </label>
            <span style={{ color: "red" }}>{errors.duration}</span>
            <input
              type="text"
              id="input2"
              name="duration"
              className="academy-admin-input"
              value={courseDetails.duration}
              onChange={handleInputChange}
            />

            <label className="labelito tw-mb-1" htmlFor="input3">
              Physical Contact Per Week
            </label>
            <span style={{ color: "red" }}>{errors.physical_contact}</span>
            <input
              type="text"
              id="input3"
              name="physical_contact"
              className="academy-admin-input"
              value={courseDetails.physical_contact}
              onChange={handleInputChange}
            />

            <label className="labelito tw-mb-1" htmlFor="input4">
              Course Overview
            </label>
            <span style={{ color: "red" }}>{errors.description}</span>
            <CKEditor
              editor={InlineEditor}
              data={courseDetails.description}
              onChange={handleEditorChange}
              config={editorConfig}
            />

            <label className="labelito tw-mb-1 tw-mt-2" htmlFor="input4">
              Course Image
            </label>
            <div className="upload tw-mt-1 tw-items-center">
              <div className="imgspot tw-overflow-hidden">
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="selected"
                    className="tw-max-h-[60px] tw-max-w-[100%]"
                  />
                )}
              </div>

              <label className="tw-cursor-pointer tw-text-[#0D6EFD]">
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={handleImageChange}
                  className="tw-hidden"
                />
                <i className="fa fa-upload" aria-hidden="true"></i> Upload Image
              </label>
            </div>
            <div className="tw-mt-2">
              <label className="labelito tw-mb-1" htmlFor="input4">
                FAQs
              </label>
              <AddFaq setFaq={setFaq} faq={faq} />
            </div>
          </div>

          <div className="tw-w-full tw-mb-8 lg:tw-w-[48%]">
            <label htmlFor="" className="labelito tw-mb-2">
              Select Course Type
            </label>
            <div className="StandardAdvancetab tw-mt-1 tw-w-full tw-mb-3">
              <div
                className={`tab ${
                  activeTab === "firstTab" ? "activecoursetype" : ""
                }`}
                onClick={() => handleTabChange("firstTab")}
              >
                Standard
              </div>
              <div
                className={`tab ${
                  activeTab === "secondTab" ? "activecoursetype" : ""
                }`}
                onClick={() => handleTabChange("secondTab")}
              >
                Advanced
              </div>
            </div>

            <div className="tab-content">
              {activeTab === "firstTab" && (
                <div className="tw-flex tw-flex-col">
                  <label htmlFor="" className="labelito tw-mb-1 tw-mt-1">
                    Standard Price
                  </label>
                  <span style={{ color: "red" }}>{errors.standardPrice}</span>
                  <input
                    type="text"
                    className="academy-admin-input"
                    name="standardPrice"
                    value={standardCourse.price}
                    onChange={handleInputChange}
                  />

                  <label className="labelito tw-mb-1">What you'll learn</label>
                  <Accordion items={standardList} handleRemove={handleRemove} />
                  <AddTopic onAdd={handleStandard} />
                </div>
              )}
              {activeTab === "secondTab" && (
                <div className="tw-flex tw-flex-col">
                  <label htmlFor="" className="labelito tw-mb-1 tw-mt-1">
                    Advanced Price
                  </label>
                  <span style={{ color: "red" }}>{errors.advancedPrice}</span>
                  <input
                    type="text"
                    className="academy-admin-input"
                    name="advancedPrice"
                    value={advancedCourse.price}
                    onChange={handleInputChange}
                  />

                  <label className="labelito tw-mb-1">What you'll learn</label>
                  <Accordion
                    items={advancedList}
                    handleRemove={removeAdvanced}
                  />
                  <AddTopic onAdd={handleAdvanced} />
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="tw-h-[200px] lg:tw-h-[20px]"></div>

      {saving && <SavingStatus />}
      {message && <StatusMessage message={message} setMessage={setMessage} />}
    </>
  );
};

export default EditCourse;
