import React, { useState, useEffect } from "react";

export default function LazyImageLoader({ src, alt }) {
  const [loaded, setLoaded] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
      setImage(img);
    };
  }, [src]);

  return (
    <div>
      {loaded ? (
        <img src={image.src} alt={alt} />
      ) : (
        <div
          style={{ width: "100%", height: "100%", backgroundColor: "#f0f0f0" }}
        />
      )}
    </div>
  );
}
