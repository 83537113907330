import React from "react";
import loading from "../../images/common/loading.svg";

const SavingStatus = () => {
  return (
    <div className="message-overlay">
      <div className="message" style={{ width: "300px", padding: "40px 66px" }}>
        <img src={loading} alt="loading" />
      </div>
    </div>
  );
};

export default SavingStatus;
