import axios from "axios";
import React, { useState } from "react";
import loading from "../../images/common/loading_white.svg";
import { ToastContainer, toast } from "react-toastify";

const SendReceipt = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    course: "",
    admission_type: "",
    course_price: "",
    duration: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/send-receipt`;
      axios
        .post(endpointUrl, formData)
        .then((response) => {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
          setIsSubmitting(false);
          setFormData({
            fullname: "",
            email: "",
            course: "",
            admission_type: "",
            course_price: "",
            duration: "",
          });
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error("Error sending form data:" + error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      // Data is not valid, display validation errors
      setErrors(validationErrors);
    }
  };

  const validateForm = (formData) => {
    let errors = {};
    if (!formData.fullname.trim()) {
      errors.fullname = "Full name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.course.trim()) {
      errors.course = "Course is required";
    }
    if (!formData.admission_type.trim()) {
      errors.admission_type = "Admission type is required";
    }
    if (!formData.course_price.trim()) {
      errors.course_price = "Course price is required";
    }
    if (!formData.duration.trim()) {
      errors.duration = "Course duration is required";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div className="uaa-container lg:tw-pt-20 tw-pt-[100px] tw-px-[20px]">
      <div className="lg:tw-text-left tw-text-center tw-text-2xl tw-pb-2">
        Fill form to send receipt
      </div>
      <ToastContainer />
      <div className="tw-flex tw-flex-col tw-justify-center w-full ">
        <form className="lg:tw-max-w-[40%] w-full" onSubmit={handleSubmit}>
          <div className="tw-mb-2">
            <label>Full name</label>
            <input
              type="text"
              placeholder="Eg: Vincent Chibundo"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              className="tw-w-full tw-h-10 tw-pl-2 tw-border-1"
            />
            {errors.fullname && (
              <span className="tw-text-[#962828]">{errors.fullname}</span>
            )}
          </div>
          <div className="tw-mb-2">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Eg: vincent@gmail.com"
              className="tw-w-full tw-h-10 tw-pl-2 tw-border-1"
            />
            {errors.email && (
              <span className="tw-text-[#962828]">{errors.email}</span>
            )}
          </div>
          <div className="tw-mb-2">
            <label>Course</label>
            <input
              type="text"
              name="course"
              placeholder="Eg: Data Science"
              value={formData.course}
              onChange={handleChange}
              className="tw-w-full tw-h-10 tw-pl-2 tw-border-1"
            />
            {errors.course && (
              <span className="tw-text-[#962828]">{errors.course}</span>
            )}
          </div>
          <div className="tw-mb-2">
            <label>Admission type</label>
            <input
              type="text"
              name="admission_type"
              placeholder="Eg: Part time or Full time"
              value={formData.admission_type}
              onChange={handleChange}
              className="tw-w-full tw-h-10 tw-pl-2 tw-border-1"
            />
            {errors.admission_type && (
              <span className="tw-text-[#962828]">{errors.admission_type}</span>
            )}
          </div>
          <div className="tw-mb-2">
            <label>Course Price</label>
            <input
              type="number"
              placeholder="Eg: 170000"
              name="course_price"
              value={formData.course_price}
              onChange={handleChange}
              className="tw-w-full tw-h-10 tw-pl-2 tw-border-1"
            />
            {errors.course_price && (
              <span className="tw-text-[#962828]">{errors.course_price}</span>
            )}
          </div>
          <div className="tw-mb-2">
            <label>Duration</label>
            <input
              type="text"
              placeholder="Eg: 6 weeks"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              className="tw-w-full tw-h-10 tw-pl-2 tw-border-1"
            />
            {errors.duration && (
              <span className="tw-text-[#962828]">{errors.duration}</span>
            )}
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`tw-bg-[#322bb0] hover:tw-bg-[#262268] hover:tw-text-white ${
              isSubmitting ? "tw-cursor-not-allowed" : ""
            } tw-px-4 border-0 tw-mt-2 tw-h-10 tw-text-white tw-rounded-[10px]`}
          >
            {isSubmitting ? (
              <>
                Sending
                <img src={loading} alt="loading" />
              </>
            ) : (
              "Send Email"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SendReceipt;
