import React from "react";

const BrowseCategory = () => {
  return (
    <section className="categories-area section-py-130">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-5 col-lg-8 col-md-10">
            <div className="categories__title-wrap text-center text-xl-start">
              <div className="section__title">
                <span className="sub-title">Uniccon Academy Courses</span>
                <h2 className="title tg-svg">
                  Browse By{" "}
                  <span className="position-relative">
                    <span
                      className="svg-icon"
                      id="svg-5"
                      data-svg-icon="assets/img/icons/title_shape.svg"
                    ></span>
                    Categories
                  </span>
                </h2>
              </div>
              <p>
                Unlock your potential with our diverse online courses, designed
                to fit your schedule and learning style. From expert-led
                tutorials to interactive modules, embark on a journey of
                knowledge and skill development from the comfort of your own
                home.
              </p>
              <div className="tg-button-wrap justify-content-center justify-content-xl-start">
                <a href="/pricing" className="btn btn-border tg-svg">
                  <span className="text">All Categories</span>{" "}
                  <span
                    className="svg-icon"
                    id="svg-6"
                    data-svg-icon="assets/img/icons/btn-arrow.svg"
                  ></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-9">
            <div className="categories__wrap">
              <img
                src="assets/img/objects/categories_shape03.svg"
                alt="shape"
                data-aos="fade-right"
              />
              <img
                src="assets/img/objects/categories_shape04.svg"
                alt="shape"
                data-aos="fade-left"
              />
              <div className="row justify-content-center row-cols-2 row-cols-md-3">
                <div className="col">
                  <div className="categories__item">
                    <a href="https://unicconacademy.com/course/graphics-design-and-motion-graphics">
                      <i className="flaticon-graphic-design"></i>
                      <span className="name">Graphic Design</span>
                    </a>
                  </div>
                </div>
                <div className="col">
                  <div className="categories__item">
                    <a href="https://unicconacademy.com/course/web-development-training-in-nigeria">
                      <i className="flaticon-email-marketing"></i>
                      <span className="name">Web Development</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center row-cols-2 row-cols-sm-3">
                <div className="col">
                  <div className="categories__item">
                    <a href="https://unicconacademy.com/course/ui-ux-design-training-in-nigeria">
                      <i className="flaticon-bars"></i>
                      <span className="name">UI/UX Design</span>
                    </a>
                  </div>
                </div>
                <div className="col">
                  <div className="categories__item">
                    <a href="https://unicconacademy.com/course/embedded-system-robotics">
                      <i className="flaticon-programming-language"></i>
                      <span className="name">AI and Robotics</span>
                    </a>
                  </div>
                </div>
                <div className="col">
                  <div className="categories__item">
                    <a href="https://unicconacademy.com/course/chemistry-and-molecular-biology-training-in-nigeria">
                      <i className="flaticon-atom"></i>
                      <span className="name">Molecular Biology</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="categories__shapes">
        <div className="categories__shapes-item rotateme">
          <img
            src="assets/img/objects/categories_shape01.png"
            data-aos="fade-up"
            alt="shape"
          />
        </div>
        <div className="categories__shapes-item" data-aos="fade-up">
          <img src="assets/img/objects/categories_shape02.png" alt="shape" />
        </div>
      </div>
    </section>
  );
};

export default BrowseCategory;
