import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "../courses/cssforcourses/courses.css";
import loading from "../../images/common/loading.svg";
import axios from "axios";
import { useAuth } from "../auth/Auth";
import SavingStatus from "../common/SavingStatus";
import StatusMessage from "../common/StatusMessage";
import AccademyDateFormat from "../common/AccademyDateFormat";

const Transactions = () => {
  const location = useLocation();
  const [myList, setMyList] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const { token } = useAuth();
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);

  const [selectedUser, setSelectedUser] = useState([]);

  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/all-transactions`, config)
      .then((response) => {
        setMyList(response.data.transactions);
        setFilteredData(response.data.transactions);
        setLoadingPage(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const toggleActionsVisibility = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null); // Hide actions if the same item is clicked again
    } else {
      setSelectedItem(item); // Show actions for the clicked item
    }
  };

  const openDeleteTransaction = () => setDeleteAdmin(!deleteAdmin);

  const savingApproval = (status) => {
    setSaving(status);
  };

  const handleMessage = (status, message) => {
    setMessage({
      status,
      message,
    });
  };

  const handleDelete = () => {
    openDeleteTransaction();
    savingApproval(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}api/delete-course/${selectedUser.id}`,
        config
      )
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          savingApproval(false);
          console.log("Form submission successful!", response.data);
          setSelectedUser(null);
          const updatedData = myList.filter(
            (item) => item.id !== selectedUser.id
          );
          setMyList(updatedData);
          setFilteredData(updatedData);
          handleMessage("success", "User deleted successfully!");
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
        savingApproval(false);
        handleMessage("error", "Error deleting User!");
      });
  };

  const itemsPerPage = 5;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(myList);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredData = myList.filter((item) =>
      Object.values(item).join(" ").toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredData);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return location.pathname === "admin/courses" ? (
    <Outlet />
  ) : (
    <div className="lg:tw-w-full tw-w-[90%] tw-py-[50px] tw-px-[5%] lg:tw-px-[0%]">
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-mb-4 tw-mt-10 lg:tw-mt-4 tw-px-2 sm:tw-px-0">
        <div className="tw-text-neutral-950 tw-text-xl tw-font-bold tw-font-['Manrope'] tw-tracking-tight tw-self-center tw-mb-2">
          {myList.length} Courses
        </div>
        <div className="tw-flex md:tw-flex-col tw-items-center tw-justify-center tw-flex-col-reverse md:tw-items-end md:tw-justify-end">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearch}
            className="tw-w-full lg:tw-w-[384px] tw-border-[#D7DCE0] tw-rounded-[6px] tw-h-[35px] tw-pl-[10px] tw-text-[16px] tw-mb-3"
          />
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Course</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {myList.length === 0 ? (
            <p className="text-center fw-bold">
              No recently submitted data available
            </p>
          ) : (
            <>
              {currentData.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.application.name}</td>
                  <td>{item.application.email}</td>
                  <td>{item.application.course}</td>
                  <td>&#8358;{item.amount}</td>
                  <td>{item.status}</td>
                  <td className="lasttable">
                    <AccademyDateFormat originalDate={item.created_at} />
                    <span
                      className="threestoke"
                      onClick={() => toggleActionsVisibility(item)}
                    >
                      ⋮
                    </span>
                    {selectedItem === item && (
                      <div>
                        <div
                          className="overlayupper"
                          onClick={() => toggleActionsVisibility(item)}
                        ></div>

                        <div className="actions">
                          <span className="action">
                            <Link
                              className="actioncourse"
                              to={`#/courses/course-detail`}
                              state={item}
                              key={index}
                            >
                              View
                            </Link>
                          </span>
                          <span className="action">
                            <Link
                              className="actioncourse"
                              onClick={() => {
                                // openDeleteTransaction();
                                // setSelectedUser(item);
                              }}
                            >
                              Delete
                            </Link>
                          </span>
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>

      <div className="pagination">
        <div>
          <h2>
            Showing {""}
            <span>
              Page {currentPage} of {totalPages}
            </span>
          </h2>
        </div>
        <div className="paginationbutton">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <div className="current_page">{currentPage}</div>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>

      {saving && <SavingStatus />}
      {message && <StatusMessage message={message} setMessage={setMessage} />}

      {deleteAdmin && (
        <DeleteConfirmationModal
          openDeleteTransaction={openDeleteTransaction}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

const DeleteConfirmationModal = ({ openDeleteTransaction, onDelete }) => (
  <div className="message-overlay" onClick={openDeleteTransaction}>
    <div className="delete-modal">
      <div className="headwarning">
        <h2 className="deletemodalhtwo">Warning</h2>

        <p className="deleteX" onClick={openDeleteTransaction}>
          x
        </p>
      </div>

      <p>Are you sure you want to delete this course?</p>
      <button className="cancel" onClick={openDeleteTransaction}>
        Cancel
      </button>
      <button className="delete tw-bg-red-900" onClick={onDelete}>
        Delete
      </button>
    </div>
  </div>
);

export default Transactions;
