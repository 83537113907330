import React, { useEffect, useState } from "react";
import filestorage from "./images/file-storage 1.png";
import "./scholarship.css";
import Approved from "./Approved";
import close from "../../images/common/closes.svg";
import loading from "../../images/common/loading.svg";
import axios from "axios";
import { useAuth } from "../auth/Auth";
import Modal from "react-modal";
import SavingStatus from "../common/SavingStatus";
import StatusMessage from "../common/StatusMessage";

const Scholarship = () => {
  const [activeTab, setActiveTab] = useState("firstTab");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const [applicant, setApplicant] = useState([]);
  const [totalApplicants, setTotalApplicants] = useState([]);
  const { token } = useAuth();
  const [loadingPage, setLoadingPage] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(false);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/all-applications`;
    axios
      .get(endpointUrl, config)
      .then((response) => {
        if (response.data) {
          const totalEntries = Object.keys(response.data).length;
          setTotalApplicants(totalEntries);
          console.log("ada", response.data);

          const dataArray = Object.values(response.data);
          setData(dataArray);
          setFilteredData(dataArray);

          const sortedData = Object.values(response.data).sort(
            (a, b) => b.id - a.id
          );

          const sortedDataObject = {};
          sortedData.forEach((item, index) => {
            sortedDataObject[index] = item;
          });
          setLoadingPage(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [token]);

  const savingApproval = (status) => {
    console.log(status);
    setSaving(status);
  };

  const handleMessage = (status, message) => {
    setMessage({
      status,
      message,
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredData = data.filter((item) =>
      Object.values(item).join(" ").toLowerCase().includes(searchTerm)
    );

    setFilteredData(filteredData);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="lg:tw-w-full tw-py-[50px] tw-px-[5%] lg:tw-px-[0%]">
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-mb-4 tw-mt-10 lg:tw-mt-4 tw-px-2 sm:tw-px-0">
          <div className="tw-text-neutral-950 tw-text-xl tw-font-bold tw-font-['Manrope'] tw-tracking-tight tw-self-center tw-mb-2">
            {totalApplicants > 0 ? totalApplicants : "0"} Scholarship Student(s)
          </div>
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearch}
            className="tw-w-full lg:tw-w-[384px] tw-border-[#D7DCE0] tw-rounded-[6px] tw-h-[35px] tw-pl-[10px] tw-text-[16px]"
          />
        </div>

        <div className="scholarshipnav">
          <div
            className={`tabu ${
              activeTab === "firstTab" ? "activeSendMoney" : ""
            }`}
            onClick={() => handleTabChange("firstTab")}
          >
            Request(s)
          </div>
          <div
            className={`tabu ${
              activeTab === "secondTab" ? "activeSendMoney" : ""
            }`}
            onClick={() => handleTabChange("secondTab")}
          >
            Approved
          </div>
        </div>

        <div className="tab-content">
          {activeTab === "firstTab" && (
            <>
              {totalApplicants.length === 0 ? (
                <div className="holder">
                  <div className="filenotindiv">
                    <img src={filestorage} alt="" />

                    <h3>No record yet</h3>
                  </div>
                </div>
              ) : (
                <div
                  className={`tw-grid tw-grid-cols-2 md:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-x-[20px] tw-mt-5 ${
                    openModal ? "tw-pointer-e vents-none" : ""
                  }`}
                >
                  {currentData.map((person, index) => (
                    <div className="" key={person.id}>
                      <div className="sch-student-list">
                        <div className="tw-text-black tw-text-sm sm:tw-text-xl tw-text-center tw-font-normal tw-font-['Manrope']">
                          {person.name
                            .toLowerCase()
                            .replace(/\b\w/g, (letter) => letter.toUpperCase())}
                        </div>
                        <div className="tw-w-full tw-overflow-x-scroll tw-text-gray-400 tw-text-xs sm:tw-text-sm tw-text-center tw-font-['Manrope']">
                          {person.email}
                        </div>
                        <button
                          className="approve tw-mt-4 tw-cursor-pointer tw-w-full hover:tw-bg-[#4e5de8]"
                          onClick={() => {
                            setApplicant(person);
                            openModal();
                          }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {openModal && (
                <ModalComponent
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  applicant={applicant}
                  token={token}
                  savingApproval={savingApproval}
                  handleMessage={handleMessage}
                />
              )}
            </>
          )}

          <div className="pagination">
            <div>
              <h2>
                Showing {""}
                <span>
                  Page {currentPage} of {totalPages}
                </span>
              </h2>
            </div>
            <div className="paginationbutton">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <div className="current_page">{currentPage}</div>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
          {activeTab === "secondTab" && <Approved />}
        </div>
      </div>

      {saving && <SavingStatus />}
      {message && <StatusMessage message={message} setMessage={setMessage} />}
    </>
  );
};

const ModalComponent = ({
  isOpen,
  onRequestClose,
  applicant,
  token,
  savingApproval,
  handleMessage,
}) => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9999,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      top: "50%",
      left: "50%",
      width: "80%",
      maxWidth: "600px",
      marginRight: "-50%",
      minHeight: "680px",
      transform: "translate(-50%, -50%)",
    },
  };

  const [formData, setFormData] = useState({
    application_id: "",
    coupon_percentage: "",
  });

  const [errors, setErrors] = useState({
    application_id: "",
    coupon_percentage: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "coupon_percentage":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Percentage must be a number";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const key in formData) {
      const error = validateInput(key, formData[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    onRequestClose();
    savingApproval(true);

    const modifiedFormData = {
      ...formData,
      application_id: applicant.id ? applicant.id : 0,
      coupon_percentage: parseInt(formData.coupon_percentage),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/approve-application`,
        modifiedFormData,
        config
      )
      .then((response) => {
        if (response.data.status === "approved") {
          savingApproval(false);
          console.log("Form submission successful!", response.data);
          handleMessage("success", "Scholarship has been approved!");
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className="tw-w-[94%] tw-mx-[3%] lg:tw-w-[600px] lg:tw-mx-[0%] tw-bg-white">
        <div className="quiz-modal-header ">
          <div className="quiz-modal-header-title">Applicant Details</div>
          <div className="quiz-modal-header-close" onClick={onRequestClose}>
            <img src={close} alt="close" />
          </div>
        </div>
        <form className="tw-m-0 tw-w-full tw-overflow-hidden">
          <div className="tw-w-full tw-flex tw-flex-col tw-h-auto tw-overflow-y-scroll">
            <div className="quiz-input-group">
              <input
                type="text"
                readOnly
                className="quiz-modal-input tw-h-[22px] w-100 readonly"
                value={applicant.name}
              />
            </div>
            <div className="quiz-input-group mt-12">
              <input
                type="text"
                readOnly
                className="quiz-modal-input tw-h-[22px] w-100 readonly"
                value={applicant.email}
              />
            </div>
            <div className="quiz-input-group mt-12">
              <input
                type="text"
                readOnly
                className="quiz-modal-input tw-h-[22px] w-100 readonly "
                value={applicant.phone}
              />
            </div>

            <div>
              <input
                type="hidden"
                name="application_id"
                value={applicant.id}
                onChange={handleInputChange}
              />
              <span style={{ color: "red" }}>{errors.application_id}</span>
            </div>

            <div className="quiz-input-group mt-12">
              <input
                type="text"
                readOnly
                className="quiz-modal-input tw-h-[22px] w-100 readonly"
                value={applicant.course}
              />
            </div>

            <div className="quiz-input-group mt-12">
              <textarea
                type="text"
                className="quiz-modal-input tw-h-[22px] tw-w-[94%] lg:tw-w-[96.5%]"
                value={applicant.body}
                readOnly
              />
            </div>

            <div className="add-scholarship tw-mt-4 mb-2">
              Add Scholarship Percentage
            </div>
            <div className="tw-flex tw-justify-between">
              <div className="quiz-input-group mt-12">
                <label className="quiz-model-label font-14">Course Price</label>
                <input
                  type="text"
                  className="quiz-modal-input tw-h-[22px] tw-w-[80%] lg:tw-w-[94%]"
                  readOnly
                  value={
                    applicant.course_price ? applicant.course_price : "Null"
                  }
                />
              </div>

              <div className="quiz-input-group mt-12 ">
                <label className="quiz-model-label font-14">
                  Percentage Apply
                </label>
                <input
                  type="number"
                  className="quiz-modal-input tw-h-[22px] tw-w-[80%] lg:tw-w-[90%]"
                  placeholder="10"
                  name="coupon_percentage"
                  value={formData.coupon_percentage}
                  onChange={handleInputChange}
                />
                <span style={{ color: "red" }}>{errors.coupon_percentage}</span>
              </div>
            </div>
            <div className="quiz-input-group mt-12 col-6">
              <label className="quiz-model-label font-14">Quiz Score</label>
              <input
                type="text"
                className="quiz-modal-input tw-h-[22px] w-100"
                readOnly
                value={
                  applicant.overall_score ? applicant.overall_score : "Not yet"
                }
              />
            </div>
            <div className="appDetailsFooter">
              <button
                className="declineBtn tw-cursor-pointer"
                onClick={onRequestClose}
              >
                Decline
              </button>
              <button
                onClick={handleSubmit}
                className="approveBtn tw-cursor-pointer"
              >
                Approve
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default Scholarship;
