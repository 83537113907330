import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./cssforcourses/view.css";
import AddTopic from "./common/AddTopic";
import Accordion from "../common/Accordion";
import { useAuth } from "../auth/Auth";
import SavingStatus from "../common/SavingStatus";
import StatusMessage from "../common/StatusMessage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import AddFaq from "./common/AddFaq";

const AddCourse = () => {
  const { token } = useAuth();
  const [message, setMessage] = useState(false);
  const [saving, setSaving] = useState(false);
  const [save, setSave] = useState(false);
  const initialCourseDetails = {
    title: "",
    description: "",
    duration: "",
    physical_contact: "",
    image: null,
    standardPrice: "",
    advancedPrice: "",
  };
  const [errors, setErrors] = useState({ initialCourseDetails });

  const [standardCourse, setStandardCourse] = useState([]);
  const [advancedCourse, setAdvancedCourse] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [editorData, setEditorData] = useState("<p>Add a description!</p>");
  const [courseDetails, setCourseDetails] = useState({
    ...initialCourseDetails,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const handleMessage = (status, message) => {
    setMessage({
      status,
      message,
    });
  };

  const savingApproval = (status) => {
    setSaving(status);
  };

  useEffect(() => {
    if (standardCourse && save) {
      const course = {
        courses: [
          {
            ...standardCourse,
          },
          {
            ...advancedCourse,
          },
        ],
      };
      console.log(course);
      handleSubmit(course);
    }
  }, [standardCourse, advancedCourse, save]);

  const validateInput = (name, value) => {
    switch (name) {
      case "title":
        return value.length < 3
          ? "Title must be at least 3 characters long"
          : "";
      case "duration":
        return value.length < 3
          ? "Duration must be at least 3 characters long"
          : "";
      case "standardPrice":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Price must be a number";
      case "advancedPrice":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Price must be a number";
      case "physical_contact":
        return value.length < 3
          ? "Physical contact must be at least 3 characters long"
          : "";
      default:
        return "";
    }
  };

  const handleSaveChanges = () => {
    for (const key in courseDetails) {
      const error = validateInput(key, courseDetails[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    setStandardCourse({
      title: courseDetails.title,
      description: editorData,
      price: courseDetails.standardPrice,
      duration: courseDetails.duration,
      physical_contact: courseDetails.physical_contact,
      course_type_id: 1,
      faq: faq,
      learning_paths: standardList,
    });

    setAdvancedCourse({
      title: courseDetails.title,
      description: editorData,
      price: courseDetails.advancedPrice,
      duration: courseDetails.duration,
      physical_contact: courseDetails.physical_contact,
      course_type_id: 2,
      faq: faq,
      learning_paths: advancedList,
    });

    setSave(true);
  };

  const handleSubmit = (course) => {
    // console.log("Adding new course:", course);

    savingApproval(true);
    setSave(false);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/add-course`, course, config)
      .then((response) => {
        console.log(response);

        if (response.data.success === true) {
          savingApproval(false);
          console.log("Form submission successful!", response.data);
          handleMessage("success", "Course added successfully!");
          setCourseDetails({ ...initialCourseDetails });
          setStandardList([]);
          setEditorData("");
          setAdvancedList([]);
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
        savingApproval(false);
        handleMessage("error", "Error adding course!");
      });
  };

  const [faq, setFaq] = useState([{ topic: "", content: "" }]);

  const [activeTab, setActiveTab] = useState("firstTab");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const [standardList, setStandardList] = useState([]);
  const [advancedList, setAdvancedList] = useState([]);

  const handleStandard = (formData) => {
    setStandardList([...standardList, formData]);
  };

  const handleAdvanced = (formData) => {
    setAdvancedList([...advancedList, formData]);
  };

  const handleRemove = (index) => {
    const updatedFormList = [...standardList];
    updatedFormList.splice(index, 1);
    setStandardList(updatedFormList);
  };

  const removeAdvanced = (index) => {
    const updatedFormList = [...advancedList];
    updatedFormList.splice(index, 1);
    setAdvancedList(updatedFormList);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const editorConfig = {
    shouldNotGroupResize: true,
  };
  console.log(faq);
  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-px-[5%] lg:tw-px-[0%] lg:tw-w-full tw-mt-20">
        <Link to={`/courses`} className="btnback">
          <i className="fa fa-chevron-left" aria-hidden="true"></i> Back
        </Link>
        <button className="academy-admin-btn" onClick={handleSaveChanges}>
          Save Changes
        </button>
      </div>

      <div
        style={{ border: "1px solid #EAEDF4" }}
        className="tw-border-2 border-solid tw-border-[#EAEDF4] lg:gap-x-[4%] tw-rounded-[8px] tw-h-auto tw-flex-col lg:tw-flex-row tw-mt-[30px] tw-pt-[41px] tw-flex tw-justify-between tw-w-full tw-px-[5%] lg:tw-px-[5%] tw-py-[20px] tw-pb-[200px] lg:tw-pb-[20px]"
      >
        <div className="tw-flex tw-flex-col tw-mb-8 lg:tw-w-[48%]">
          <label className="labelito tw-mb-1 " htmlFor="input1">
            Course Title
          </label>
          <span style={{ color: "red" }}>{errors.title}</span>
          <input
            className="academy-admin-input"
            type="text"
            id="input1"
            name="title"
            value={courseDetails.title}
            onChange={handleInputChange}
          />

          <label className="labelito tw-mb-1" htmlFor="input2">
            Course Duration
          </label>
          <span style={{ color: "red" }}>{errors.duration}</span>
          <input
            type="text"
            id="input2"
            name="duration"
            className="academy-admin-input"
            value={courseDetails.duration}
            onChange={handleInputChange}
          />

          <label className="labelito tw-mb-1" htmlFor="input3">
            Physical Contact Per Week
          </label>
          <span style={{ color: "red" }}>{errors.physical_contact}</span>
          <input
            type="text"
            id="input3"
            name="physical_contact"
            className="academy-admin-input"
            value={courseDetails.physical_contact}
            onChange={handleInputChange}
          />

          <label className="labelito tw-mb-1" htmlFor="input4">
            Course Overview
          </label>
          <span style={{ color: "red" }}>{errors.description}</span>
          <CKEditor
            editor={InlineEditor}
            data={courseDetails.description}
            onChange={handleEditorChange}
            config={editorConfig}
          />
          {/* <textarea
            type="text"
            id="input4"
            name="description"
            className="academy-admin-input tw-pt-2"
            value={courseDetails.description}
            onChange={handleInputChange}
          /> */}

          <label className="labelito tw-mb-0 tw-mt-3" htmlFor="input4">
            Course Image
          </label>
          <div className="upload  tw-mt-1  ">
            <div className="imgspot tw-overflow-hidden">
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="selected"
                  className="tw-max-h-[60px] tw-max-w-[100%]"
                />
              )}
            </div>

            <label className="tw-cursor-pointer tw-text-[#0D6EFD]">
              <input
                type="file"
                onChange={handleImageChange}
                className="tw-hidden"
              />
              <i className="fa fa-upload" aria-hidden="true"></i> Upload Image
            </label>
          </div>
          <div className="tw-mt-2">
            <label className="labelito tw-mb-1" htmlFor="input4">
              FAQs
            </label>
            <AddFaq setFaq={setFaq} faq={faq} />
          </div>
        </div>

        <input
          name="quality"
          value={courseDetails.quality}
          onChange={handleInputChange}
          type="hidden"
        />

        <div className="tw-w-full tw-mb-8 lg:tw-w-[48%]">
          <label htmlFor="" className="labelito tw-mb-2">
            Select Course Type
          </label>
          <div className="StandardAdvancetab tw-mt-1 tw-w-full tw-mb-3">
            <div
              className={`tab ${
                activeTab === "firstTab" ? "activecoursetype" : ""
              }`}
              onClick={() => handleTabChange("firstTab")}
            >
              Standard
            </div>
            <div
              className={`tab ${
                activeTab === "secondTab" ? "activecoursetype" : ""
              }`}
              onClick={() => handleTabChange("secondTab")}
            >
              Advanced
            </div>
          </div>

          <div className="tab-content">
            {activeTab === "firstTab" && (
              <div className="tw-flex tw-flex-col">
                <label htmlFor="" className="labelito tw-mb-1 tw-mt-1">
                  Standard Price
                </label>
                <span style={{ color: "red" }}>{errors.standardPrice}</span>
                <input
                  type="text"
                  className="academy-admin-input"
                  name="standardPrice"
                  value={courseDetails.standardPrice}
                  onChange={handleInputChange}
                />

                <label className="labelito tw-mb-1">What you'll learn</label>
                <Accordion items={standardList} handleRemove={handleRemove} />
                <AddTopic onAdd={handleStandard} />
              </div>
            )}
            {activeTab === "secondTab" && (
              <div className="tw-flex tw-flex-col">
                <label htmlFor="" className="labelito tw-mb-1 tw-mt-1">
                  Advanced Price
                </label>
                <span style={{ color: "red" }}>{errors.advancedPrice}</span>
                <input
                  type="text"
                  className="academy-admin-input"
                  name="advancedPrice"
                  value={courseDetails.advancedPrice}
                  onChange={handleInputChange}
                />

                <label className="labelito tw-mb-1">What you'll learn</label>
                <Accordion items={advancedList} handleRemove={removeAdvanced} />
                <AddTopic onAdd={handleAdvanced} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="tw-h-[200px] lg:tw-h-[20px]"></div>

      {saving && <SavingStatus />}
      {message && <StatusMessage message={message} setMessage={setMessage} />}
    </>
  );
};

export default AddCourse;
