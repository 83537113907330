import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import logo from "../assets/logo_white.svg";
import defaultImage from "../../images/home/blocktwo.png";
import loading from "../../images/common/loading.svg";
import { useNavigate, useParams } from "react-router-dom";

import image from "../../images/home/datasciencetwo.png";

const CourseDetail = () => {
  const { slug } = useParams();

  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingRecent, setLoadingRecent] = useState(true);
  const [recentCourse, setRecentCourse] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [faqs, setFaqs] = useState([]);
  // const [advancedCourse, setAdvancedCourse] = useState([]);
  // const [standardCourse, setStandardCourse] = useState([]);
  const [discounted, setDiscounted] = useState("");
  const navigate = useNavigate();

  const [randomCharacters, setRandomCharacters] = useState([]);

  useEffect(() => {
    generateRandomCharacters();
  }, []);

  const generateRandomCharacters = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let generatedCharacters = "";

    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      generatedCharacters += characters.charAt(randomIndex);
    }

    setRandomCharacters(generatedCharacters.split(""));
  };

  useEffect(() => {
    if (!slug) {
      navigate("/pricing");
    }

    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/get-course/${slug}`;

    axios
      .get(endpointUrl)
      .then((response) => {
        console.log("data", response.data.data);
        // setStandardCourse(response.data.data.standard);
        // setAdvancedCourse(response.data.data.advanced);
        setSelectedCourse(response.data.data.standard);
        setSelectedCourse(response.data.data.standard);
        const faqArray =
          response.data.data.standard.faq == null
            ? []
            : JSON.parse(response.data.data.standard.faq || []);
        setFaqs(faqArray);
        const myImage = response.data.data.standard.image
          ? "https://apis.unicconacademy.com/storage/" +
            response.data.data.standard.image
          : image;
        setSelectedImage(myImage);
        setDiscounted(response.data.data.standard.price);
        setLoadingPage(false);
      })
      .catch((error) => {
        // if (error.response.status === 500) {
        //   window.location.href = "/not-found";
        // }
        console.error("Error:", error);
      });
  }, [slug]);

  useEffect(() => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/filtered-courses`;

    axios
      .get(endpointUrl)
      .then((response) => {
        console.log("recent", response.data.courses);
        setRecentCourse(response.data.courses);
        setLoadingRecent(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const onFaqClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const [activeTopic, setActiveTopic] = useState(null);

  const onTopicClick = (index) => {
    setActiveTopic(index === activeTopic ? null : index);
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-mb-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return (
    <main className="main-area fix">
      <section className="courses__breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="courses__breadcrumb-content">
                <a href="#/" className="category">
                  {selectedCourse.title}
                </a>
                <h3 className="title">{selectedCourse.title}</h3>
                <p>
                  Experience professional knowledge gain training on{" "}
                  {selectedCourse.title}
                </p>
                <ul className="courses__item-meta list-wrap">
                  <li>
                    <div className="author">
                      <a href="#/">
                        <img
                          src={logo}
                          style={{ width: "50px", height: "50px" }}
                          alt="img"
                        />
                      </a>
                      <a href="#/">UIT</a>
                    </div>
                  </li>
                  <li>
                    <i className="flaticon-file"></i>{" "}
                    {selectedCourse.learning_paths.length + 3}
                  </li>
                  <li>
                    <i className="flaticon-timer"></i> {selectedCourse.duration}
                  </li>
                  <li>
                    <i className="flaticon-user-1"></i> 18
                  </li>
                  <li>
                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <span className="rating-count">(4.8)</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="courses-details-area section-pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8">
              <div className="courses__details-wrapper">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="info-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#info"
                      type="button"
                      role="tab"
                      aria-controls="info"
                      aria-selected="true"
                    >
                      Course Information
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="review-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#review"
                      type="button"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      Reviews
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="info"
                    role="tabpanel"
                    aria-labelledby="info-tab"
                  >
                    <div
                      className="courses__details-content"
                      dangerouslySetInnerHTML={{
                        __html: selectedCourse.description,
                      }}
                    ></div>
                    <div className="courses__details-curriculum">
                      <h4 className="title">Curriculum</h4>
                      {selectedCourse.learning_paths.length === 0 ? (
                        <h2 className="empty">Empty for now</h2>
                      ) : (
                        <div className="accordion">
                          {selectedCourse.learning_paths.map((topic, id) => {
                            const isActive = id === activeTopic;
                            return (
                              <div className="accordion-item" key={id}>
                                <h2 className="accordion-header">
                                  <button
                                    className={`accordion-button ${
                                      isActive ? "" : "collapsed"
                                    }`}
                                    type="button"
                                    onClick={() => onTopicClick(id)}
                                  >
                                    {topic.topic}
                                  </button>
                                </h2>
                                <div
                                  id={`collapseOne${id}`}
                                  className={`accordion-collapse collapse ${
                                    isActive ? "show" : ""
                                  }`}
                                >
                                  <div className="accordion-body">
                                    <ul className="list-wrap">
                                      {topic.subtopics.map(
                                        (subtopic, subId) => (
                                          <li
                                            key={subId}
                                            className="course-item"
                                          >
                                            <a
                                              href="#/"
                                              className="course-item-link"
                                            >
                                              <span class="item-name">
                                                {subtopic.subtopic}
                                              </span>
                                              <div class="course-item-meta">
                                                <span class="item-meta duration">
                                                  {""}
                                                </span>
                                                <span class="item-meta course-item-status">
                                                  <img
                                                    src="https://unicconacademy.com/assets/img/icons/lock.svg"
                                                    alt="icon"
                                                  />
                                                </span>
                                              </div>
                                            </a>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>

                    <div className="courses__details-curriculum">
                      <h4 className="title">Frequently Asked Questions</h4>
                      <div className="accordion" id="accordionExample">
                        {selectedCourse.faq === null ? (
                          <div>empty</div>
                        ) : (
                          <>
                            {faqs.map((faq, index) => {
                              const isActive = index === activeIndex;
                              return (
                                <div className="accordion-item" key={index}>
                                  <h2 className="accordion-header">
                                    <button
                                      className={`accordion-button ${
                                        isActive ? "" : "collapsed"
                                      }`}
                                      type="button"
                                      onClick={() => onFaqClick(index)}
                                    >
                                      {faq.topic}
                                    </button>
                                  </h2>
                                  <div
                                    className={`accordion-collapse collapse ${
                                      isActive ? "show" : ""
                                    }`}
                                  >
                                    <div className="accordion-body">
                                      <div className="tw-p-6">
                                        {faq.content}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="review"
                    role="tabpanel"
                    aria-labelledby="review-tab"
                  >
                    <div className="courses__details-reviews">
                      <h4 className="title">Student Ratings & Reviews</h4>
                      <div className="course-rate">
                        <div className="course-rate__summary">
                          <div className="course-rate__summary-value">4.8</div>
                          <div className="course-rate__summary-stars">
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                          <div className="course-rate__summary-text">
                            Total 12 Ratings
                          </div>
                        </div>
                        <div className="course-rate__details">
                          <div className="course-rate__details-row">
                            <div className="course-rate__details-row-star">
                              9<i className="fas fa-star"></i>
                            </div>
                            <div className="course-rate__details-row-value">
                              <div className="rating-gray"></div>
                              <div
                                className="rating"
                                style={{ width: "80%" }}
                                title="80%"
                              ></div>
                              <span className="rating-count">9</span>
                            </div>
                          </div>
                          <div className="course-rate__details-row">
                            <div className="course-rate__details-row-star">
                              2<i className="fas fa-star"></i>
                            </div>
                            <div className="course-rate__details-row-value">
                              <div className="rating-gray"></div>
                              <div
                                className="rating"
                                style={{ width: "20%" }}
                                title="20%"
                              ></div>
                              <span className="rating-count">2</span>
                            </div>
                          </div>
                          <div className="course-rate__details-row">
                            <div className="course-rate__details-row-star">
                              3<i className="fas fa-star"></i>
                            </div>
                            <div className="course-rate__details-row-value">
                              <div className="rating-gray"></div>
                              <div
                                className="rating"
                                style={{ width: "10%" }}
                                title="10%"
                              ></div>
                              <span className="rating-count">1</span>
                            </div>
                          </div>
                          <div className="course-rate__details-row">
                            <div className="course-rate__details-row-star">
                              2<i className="fas fa-star"></i>
                            </div>
                            <div className="course-rate__details-row-value">
                              <div className="rating-gray"></div>
                              <div
                                className="rating"
                                style={{ width: "0%" }}
                                title="0%"
                              ></div>
                              <span className="rating-count">0</span>
                            </div>
                          </div>
                          <div className="course-rate__details-row">
                            <div className="course-rate__details-row-star">
                              1<i className="fas fa-star"></i>
                            </div>
                            <div className="course-rate__details-row-value">
                              <div className="rating-gray"></div>
                              <div
                                className="rating"
                                style={{ width: "0%" }}
                                title="0%"
                              ></div>
                              <span className="rating-count">0</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div id="course-reviews">
                        <h4 className="course-review-head">Reviews (01)</h4>
                        <ul className="list-wrap">
                          <li>
                            <div className="review-author">
                              <img
                                src="assets/img/blog/comment01.png"
                                alt="img"
                              />
                            </div>
                            <div className="review-author-info">
                              <div className="review-stars-rated">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                              </div>
                              <h5 className="user-name">
                                Admin{" "}
                                <span className="date">August 5, 2023</span>
                              </h5>
                              <p>
                                Sed ut perspiciatis unde omnis iste natus error
                                sit voluptatem accusantium doloremque laudantiu
                                meature areawtyt totam rem aperiam, eaque ipsa
                                quae ab illo inventore veritatis.
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div> */}
                      <div className="course-review-form">
                        <h4 className="course-review-head">Write a review</h4>
                        <form action="#">
                          <div className="row">
                            <div className="col-sm-6">
                              <input type="text" placeholder="Your Name" />
                            </div>
                            <div className="col-sm-6">
                              <input type="email" placeholder="Your Email" />
                            </div>
                          </div>
                          <input type="text" placeholder="Review Title" />
                          <div className="course-form-rating">
                            <span>Select Rating:</span>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                            <i className="fas fa-star"></i>
                          </div>
                          <textarea placeholder="Type Comments"></textarea>
                          <button className="btn">Submit your Review</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <aside className="courses__details-sidebar">
                <div className="event-widget">
                  <div className="thumb">
                    <img
                      src={selectedImage ? selectedImage : defaultImage}
                      alt="img"
                    />
                    <a href="#/" className="popup-video">
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                  <div className="event-cost-wrap">
                    <h4 className="price">
                      <strong>Costs:</strong>&#x20A6;
                      {discounted.toLocaleString()}
                    </h4>
                    <a
                      href={`/checkout/${
                        selectedCourse.id
                      }/${randomCharacters.join("")}`}
                      className="btn"
                    >
                      Enroll This Now
                    </a>
                    <div className="event-information-wrap">
                      <h6 className="title">Include This Course</h6>
                      <ul className="list-wrap">
                        <li>
                          <i className="flaticon-timer"></i>Duration{" "}
                          <span>{selectedCourse.duration}</span>
                        </li>
                        <li>
                          <i className="flaticon-file"></i>Estimated Seat{" "}
                          <span>250</span>
                        </li>
                        <li>
                          <i className="flaticon-user-1"></i>Access
                          <span>Full</span>
                        </li>
                        <li>
                          <i className="flaticon-bars"></i>Laguage{" "}
                          <span>English</span>
                        </li>
                        <li>
                          <i className="flaticon-flash"></i>Certificate
                          <span>100%</span>
                        </li>
                        <li>
                          <i className="flaticon-share"></i>Share
                          <ul className="list-wrap event-social">
                            <li>
                              <a href="#/">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#/">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#/">
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#/">
                                <i className="fab fa-youtube"></i>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="blog-widget">
                  <h4 className="widget-title">Related Courses</h4>
                  {loadingRecent ? (
                    <div className="tw-text-center tw-mt-40 tw-mb-40 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
                      <img src={loading} alt="loader" className="tw-mb-5" />
                      Loading...
                    </div>
                  ) : (
                    <>
                      {recentCourse.map((service, index) => (
                        <div className="rc-post-item" key={index}>
                          <div className="rc-post-thumb">
                            <a href={`/course/${service.slug}`}>
                              <img
                                src={
                                  service.image ? service.image : defaultImage
                                }
                                alt="title"
                              />
                            </a>
                          </div>
                          <div className="rc-post-content">
                            <h4 className="title">
                              <a href={`/course/${service.slug}`}>
                                {service.title}
                              </a>
                            </h4>
                            <span className="price">
                              &#x20A6;{service.price.toLocaleString()}
                            </span>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CourseDetail;
