
import React, { useEffect, useState } from "react";
import { Link,useLocation } from "react-router-dom";
import axios from "axios";
import "../courses/cssforcourses/courses.css";
import ProgressBar from "@ramonak/react-progress-bar";


const DeleteConfirmationModal = ({ onClose, onDelete }) => (
  <div className="modal-overlay" onClick={onClose}>
    <div className="delete-modal">
      <div className="headwarning">
        <h2 className="deletemodalhtwo">Warning</h2>

        <p className="deleteX" onClick={onClose}>
          x
        </p>
      </div>

      <p>Are you sure you want to delete this student?</p>
      <button className="cancel" onClick={onClose}>
        Cancel
      </button>
      <button className="delete" onClick={onDelete}>
        Delete
      </button>
    </div>
  </div>
);


const Approved = () => {


   const location = useLocation();
   const [searchQuery, setSearchQuery] = useState("");
   const [myList, setMyList] = useState([]);
   const [selectedItem, setSelectedItem] = useState(null);
   const [currentPage, setCurrentPage] = useState(1);
   const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
  const itemsPerPage = 5;
  

    useEffect(() => {
      const endpointUrl = `https://smartsee-admin.cloudfocuss.com/api/all-courses`;

      axios
        .get(endpointUrl)
        .then((response) => {
          setMyList(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }, []);
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = myList.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentData = filteredData.slice(startIndex, endIndex);

  const toggleOptionsVisibility = (item) => {
    if (selectedItem === item) {
      setSelectedItem(null); // Hide options if the same item is clicked again
    } else {
      setSelectedItem(item); // Show options for the clicked item
    }
  };

  // Calculate the total number of pages
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setDeleteModalVisibility(true);
  };

  const closeDeleteModal = () => {
    setSelectedItem(null);
    setDeleteModalVisibility(false);
  };

  const handleDelete = () => {
    // Implement your delete logic here
    console.log("Deleting course:", selectedItem.title);
    // Close the modal after deletion
    closeDeleteModal();
  };

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Course</th>
            <th>Completion Rate</th>
            <th>Admission Type</th>
            <th>Issue Certificate</th>
            <th>Enrolled Date</th>
          </tr>
        </thead>
        <tbody>
          {currentData.map((item) => (
            <tr key={item.id}>
              <td>Simon Ojoma Glory</td>
              <td>Chemistry</td>
              <td>
                <ProgressBar
                  completed={60}
                  maxCompleted={100}
                  completedClassName="barCompleted"
                  labelClassName="label"
                  barContainerClassName="container"
                />
              </td>
              <td>Scholarship</td>
              <td>{item.types.Advanced.toLocaleString()}</td>
              <td className="lasttable">
                May 27, 2020 | <span className="">12:38 PM</span>
                <span
                  className="threestoke"
                  onClick={() => toggleOptionsVisibility(item)}
                >
                  ⋮
                </span>
                {selectedItem === item && (
                  <div className="options">
                    <span className="option">
                      <Link
                        className="optioncourse"
                        onClick={() => openDeleteModal(item)}
                      >
                        Delete
                      </Link>
                    </span>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <div>
          <h2>
            Showing {""}
            <span>
              Page {currentPage} of {totalPages}
            </span>
          </h2>
        </div>
        <div className="paginationbutton">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <div className="current_page">{currentPage}</div>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={endIndex >= filteredData.length}
          >
            Next
          </button>
        </div>
      </div>

      {isDeleteModalVisible && (
        <DeleteConfirmationModal
          onClose={closeDeleteModal}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
}

export default Approved