import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../sidebar/navlogo.png";
import "./sidebar.css";
import { useAuth } from "../../admin/auth/Auth";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user } = useAuth();

  const closeSidebar = () => {
    toggleSidebar();
  };

  return (
    <>
      <div
        className={`nav tw-z-[9999] tw-h-[100vh] tw-absolute lg:tw-relative tw-bg-white lg:tw-bg-transparent lg:tw-w-[343px] ${
          isOpen
            ? "tw-block tw-transition tw-duration-300 tw-ease-in-out "
            : "tw-hidden"
        } lg:tw-block`}
      >
        <div className="logolist tw-pl-[30px]">
          <div className="imagelogodash ">
            <Link onClick={closeSidebar} to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>

          <div className="navlist">
            <ul>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/dashboard"
                >
                  <i className="fa-solid fa-user-plus"></i>
                  Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/courses"
                >
                  <i className="fa-solid fa-gear"></i>
                  Courses
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/student"
                >
                  <i className="fa-solid fa-users"></i>
                  Students
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/admin/scholarships"
                >
                  <i className="fa-solid fa-handshake-angle"></i>
                  Scholarships
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/admin/user-management"
                >
                  <i className="fa-solid fa-lock"></i>
                  Admin Management
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/admin/organization-setting"
                >
                  <i className="fa-solid fa-building"></i>
                  Organization Setting
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/admin/quiz-settings"
                >
                  <i className="fa-solid fa-cog"></i>
                  Quiz Settings
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/admin/send-receipt"
                >
                  <i className="fa-solid fa-bank"></i>
                  Receipt
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="navlinke"
                  onClick={closeSidebar}
                  to="/admin/transactions"
                >
                  <i className="fa-solid fa-bank"></i>
                  Transactions
                </NavLink>
              </li>
              {/* <li>
                <NavLink className="navlinke" to="#admin/certificate">
                  <i className="fa-solid fa-certificate"></i>
                  Certificates
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>

        <div className="name">
          {/* <h3>{user.email}</h3>
          <p>{user.name}</p> */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
