import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
//import "./index.css";
import Home from "./frontend/pages/Home";
import Courses from "./frontend/pages/AllCourses";
import Footer from "./frontend/layout/Footer";
import Success from "./frontend/pages/sucess";
import Quiz from "./frontend/pages/quiz";
import RequireAuth from "./admin/auth/RequireAuth";
import Login from "./admin/auth/Login";
import Dashboard from "./admin/dashboard/Dashboard";
import Mainlayout from "./components/layout/Mainlayout";
import DashboardCourses from "./admin/courses/Courses";
import Student from "./admin/student/Student";
import Scholarship from "./admin/scholarship/Scholarship";
import ViewCourse from "./admin/courses/ViewCourse";
import AddCourse from "./admin/courses/AddCourse";
import Adminmanagement from "./admin/adminmanagement/Adminmanagement";
import Organizationsetting from "./admin/organizationsetting/organizationsetting";
import Certificate from "./admin/certificate/Certificate";
import { QuizSettings } from "./admin/quiz/QuizSettings";
import MyQuiz from "./frontend/pages/MyQuiz";
import QuizScores from "./admin/quiz/QuizScores";
import AddQuizQuestions from "./admin/quiz/AddQuizQuestions";
import ViewQuizQuestions from "./admin/quiz/ViewQuizQuestions";
import EditCourse from "./admin/courses/EditCourse";
import Transactions from "./admin/transactions/Transactions";
import Header from "./frontend/layout/Header";
import About from "./frontend/pages/About";
import CourseDetail from "./frontend/pages/CourseDetail";
import NotFound from "./frontend/pages/NotFound";
import Contact from "./frontend/pages/Contact";
import Checkout from "./frontend/pages/Checkout";
import SendReceipt from "./admin/receipt/SendReceipt";
import AllInstructors from "./frontend/pages/AllInstructors";

const hideNavbarRoutes = [
  "/quiz",
  "/my-quiz",
  "/admin",
  "dashboard",
  "/courses",
  "/admin/certificate",
  "/admin/organization-setting",
  "/student",
  "/scholarships",
  "/quiz-scores",
];
const hideFooterRoutes = [
  "/quiz",
  "/my-quiz",
  "/admin",
  "/dashboard",
  "/courses",
  "/admin/certificate",
  "/admin/organization-setting",
  "/student",
  "/scholarships",
  "/quiz-scores",
];

function ConditionalNav() {
  const location = useLocation();
  const shouldShowNavbar = !hideNavbarRoutes.some((route) =>
    location.pathname.includes(route)
  );

  return shouldShowNavbar ? <Header /> : null;
}

function ConditionalFooter() {
  const location = useLocation();
  const shouldShowFooter = !hideFooterRoutes.some((route) =>
    location.pathname.includes(route)
  );

  return shouldShowFooter ? <Footer /> : null;
}

function App() {
  return (
    <BrowserRouter>
      <ConditionalNav />
      <Routes>
        {/* Public Routes (No Authentication Needed) */}
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/course/:slug" element={<CourseDetail />} />
        <Route path="/pricing" element={<Courses />} />
        <Route path="/checkout/:id/:title" element={<Checkout />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/all-instructors" element={<AllInstructors />} />
        <Route path="/register/success" element={<Success />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/my-quiz" element={<MyQuiz />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/not-found" element={<NotFound />} />

        {/* Authenticated Routes (Wrapped in AuthProvider) */}

        <Route path="/admin" element={<Login />} />
        <Route element={<Mainlayout />}>
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/courses"
            element={
              <RequireAuth>
                <DashboardCourses />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/courses/course-detail"
            element={
              <RequireAuth>
                <ViewCourse />
              </RequireAuth>
            }
          />
          <Route
            path="/courses/:id/edit-course"
            element={
              <RequireAuth>
                <EditCourse />
              </RequireAuth>
            }
          />
          <Route
            path="/courses/add-course"
            element={
              <RequireAuth>
                <AddCourse />
              </RequireAuth>
            }
          />
          <Route
            path="/student"
            element={
              <RequireAuth>
                <Student />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/quiz-settings"
            element={
              <RequireAuth>
                <QuizSettings />
              </RequireAuth>
            }
          />

          <Route
            path="/admin/quiz-scores"
            element={
              <RequireAuth>
                <QuizScores />
              </RequireAuth>
            }
          />

          <Route
            path="/admin/add-quiz-questions"
            element={
              <RequireAuth>
                <AddQuizQuestions />
              </RequireAuth>
            }
          />

          <Route
            path="/admin/view-quiz-questions"
            element={
              <RequireAuth>
                <ViewQuizQuestions />
              </RequireAuth>
            }
          />

          <Route
            path="/admin/scholarships"
            element={
              <RequireAuth>
                <Scholarship />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/send-receipt"
            element={
              <RequireAuth>
                <SendReceipt />
              </RequireAuth>
            }
          />

          <Route
            path="/admin/transactions"
            element={
              <RequireAuth>
                <Transactions />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/user-management"
            element={
              <RequireAuth>
                <Adminmanagement />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/organization-setting"
            element={
              <RequireAuth>
                <Organizationsetting />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/certificate"
            element={
              <RequireAuth>
                <Certificate />
              </RequireAuth>
            }
          />
        </Route>

        {/* 404 Not Found Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ConditionalFooter />
    </BrowserRouter>
  );
}

export default App;
