import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../auth/login.css";
import logo from "../auth/images/logo.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useAuth } from "./Auth";
import diamond from "../../images/common/diamond.svg";
import axios from "axios";
import loading from "../../images/common/loading_white.svg";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [saving, setSaving] = useState(false);

  const redirectPath = location.state?.path || "/dashboard";

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Invalid email address";
      case "password":
        return value.length < 6
          ? "Password must be at least 6 characters long"
          : "";
      default:
        return "";
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    for (const key in formData) {
      const error = validateInput(key, formData[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    setSaving(true);

    try {
      const response = await axios.post(
        "https://apis.unicconacademy.com/api/login",
        formData
      );
      if (response.data.success && response.data.token) {
        //console.log(response.data.user.email);
        setSaving(false);
        const token = response.data.token;
        const expirationTimestamp = new Date().getTime() + 15 * 60 * 1000;

        localStorage.setItem("authToken", token);
        localStorage.setItem("user", response.data.user);
        // localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem(
          "expirationTimestamp",
          expirationTimestamp.toString()
        );

        login(response.data.user, token);
        navigate(redirectPath, { replace: true });
      } else {
        //    console.log("Login error", response);
        setSaving(false);
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      // console.error("Error during login:", error);
      setSaving(false);
      toast.error(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      <nav className="navlogin">
        <a href="/">
          <img src={logo} alt="" />
        </a>
      </nav>

      <div className="quiz-diamond">
        <img src={diamond} alt="Diamond" />
      </div>

      <div className="tw-w-full tw-h-[100vh] tw-flex tw-justify-center tw-overflow-hidden">
        <div className="tw-px-5 tw-w-full tw-flex tw-justify-center">
          <div className="tw-w-full lg:tw-w-[608px] tw-h-[400px] tw-rounded-[24px] tw-bg-white tw-flex tw-flex-col tw-justify-center tw-items-center tw-shadow-xl tw-mt-[89px]">
            <div className="tw-w-full">
              <div className="tw-text-blue-600 tw-text-[22px] tw-text-center lg:tw-text-[32px] tw-font-semibold tw-font-['Roboto'] tw-leading-[50.40px]">
                Login as an Admin
              </div>
              <div className="tw-text-center tw-text-stone-950 tw-text-base tw-font-normal tw-font-['Manrope'] tw-mt-1  ">
                Access dashboard
              </div>
            </div>

            <ToastContainer />
            <form className="tw-w-full tw-mt-[69px] tw-px-0">
              <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
                <div className="tw-w-[88%] tw-flex tw-flex-col tw-mb-4 tw-gap-1">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className=" tw-h-[32px] tw-pl-2 tw-rounded tw-border tw-border-gray-400"
                    onChange={handleInputChange}
                    autoComplete="true"
                  />
                  <span style={{ color: "red" }}>{errors.email}</span>
                </div>

                <div className="tw-w-[88%] tw-flex tw-flex-col tw-mb-4 tw-gap-1">
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    className=" tw-h-[32px] tw-pl-2 tw-rounded tw-border tw-border-gray-400"
                    onChange={handleInputChange}
                    autoComplete="true"
                  />
                  <span style={{ color: "red" }}>{errors.password}</span>
                </div>

                <button
                  className="tw-w-[88%] tw-flex tw-items-center tw-justify-center tw-h-10 tw-py-2 tw-bg-blue-600 tw-rounded-lg tw-border-0 tw-text-[#fff] tw-cursor-pointer"
                  onClick={handleLogin}
                >
                  Sign in{" "}
                  {saving && (
                    <img src={loading} alt="saving" className="tw-mx-2" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="quiz-diamond-two">
        <img src={diamond} alt="Diamond" />
      </div>
    </>
  );
};

export default Login;
