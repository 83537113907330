import { useEffect, useState } from "react";
import logo from "../assets/logo.svg";

const Header = () => {
  const handleOpenMenu = () => {
    document.body.classList.add("mobile-menu-visible");
  };
  const handleCloseMenu = () => {
    document.body.classList.remove("mobile-menu-visible");
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("mobile-menu-visible");
    };
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div id="preloader" style={{ display: loading ? "flex" : "none" }}>
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
      </div>
      <header className="tg-header__style-three">
        <div className="tg-header__top">
          <div className="container custom-container">
            <div className="row">
              <div className="col-md-6">
                <ul className="tg-header__top-info list-wrap">
                  <li>
                    <i className="flaticon-pin"></i>{" "}
                    <span>
                      3rd Floor, Lanre Shittu House, Mabushi, Abuja. 900108
                    </span>
                  </li>
                  <li>
                    <i className="flaticon-email"></i>{" "}
                    <a href="mailto:info@uniccongroup.com">
                      info@uniccongroup.com
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="tg-header__top-social list-wrap">
                  <li>Follow Us On :</li>
                  <li>
                    <a href="https://www.facebook.com/unicconacademy">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/UnicconAcademy">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/unicconacademy/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/unicconacademy/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@UNICCONAcademy">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="sticky-header" className="tg-header__area">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler" onClick={handleOpenMenu}>
                  <i className="tg-flaticon-menu-1"></i>
                </div>
                <div className="tgmenu__wrap">
                  <nav className="tgmenu__nav">
                    <div className="logo">
                      <a href="/">
                        <img src={logo} alt="Logo" />
                      </a>
                    </div>
                    <div className="tgmenu__categories d-none d-md-block">
                      <div className="dropdown">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12 12H6.85714V6.85714H12V12ZM5.14286 12H0V6.85714H5.14286V12ZM12 5.14286H6.85714V0H12V5.14286ZM5.14286 5.14286H0V0H5.14286V5.14286Z"
                              fill="currentcolor"
                            />
                          </svg>
                          Categories
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1">
                          <li>
                            <a className="dropdown-item" href="/pricing">
                              Web Development
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/pricing">
                              Data Science
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/pricing">
                              App Development
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/pricing">
                              Ai & Robotics
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" href="/pricing">
                              UI & UX Design
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tgmenu__navbar-wrap tgmenu__main-menu d-none d-xl-flex">
                      <ul className="navigation">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about-us">About us</a>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#/">Courses</a>
                          <ul className="sub-menu">
                            <li>
                              <a href="/pricing">All Courses</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="https://chuksekwuemefoundation.org/apply-for-scholarship">
                            Scholarship
                          </a>
                        </li>
                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="/contact-us">Contact us</a>
                        </li>
                      </ul>
                    </div>

                    <div className="tgmenu__action">
                      <ul className="list-wrap">
                        <li className="header-btn">
                          <a href="/pricing" className="btn">
                            Enroll Now
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                {/* <!-- Mobile Menu  --> */}
                <div className="tgmobile__menu">
                  <nav className="tgmobile__menu-box">
                    <div className="close-btn pt-4" onClick={handleCloseMenu}>
                      <i className="tg-flaticon-close-1"></i>
                    </div>
                    <div className="nav-logo">
                      <a href="/">
                        <img src={logo} alt="Logo" />
                      </a>
                    </div>

                    <div className="tgmobile__menu-outer">
                      <ul className="navigation">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about-us">About us</a>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="/pricing">Courses</a>
                          <ul className="sub-menu">
                            <li>
                              <a href="/">All Courses</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="https://chuksekwuemefoundation.org/apply-for-scholarship">
                            Scholarship
                          </a>
                        </li>
                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="/contact-us">Contact us</a>
                        </li>
                      </ul>
                    </div>
                    <div className="tgmenu__action">
                      <ul className="list-wrap">
                        <li className="header-btn">
                          <a href="/pricing" className="btn">
                            Enroll Now
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="social-links">
                      <ul className="list-wrap">
                        <li>
                          <a href="https://www.facebook.com/unicconacademy">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/UnicconAcademy">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/unicconacademy/">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/unicconacademy/">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/@UNICCONAcademy">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div
                  className="tgmobile__menu-backdrop"
                  onClick={handleCloseMenu}></div>
                {/* <!-- End Mobile Menu --> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
