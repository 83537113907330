import React, { useEffect, useRef, useState } from "react";
import { QuizSettingMenu } from "../common/QuizSettingMenu";
import { Link } from "react-router-dom";
import not_found from "../../images/common/not_found.svg";
import option_selected from "../../images/common/list_quiz.svg";
import option_empty from "../../images/common/option_empty.svg";
import close from "../../images/common/closes.svg";
import time from "../../images/common/time.svg";
import loading from "../../images/common/loading.svg";
import edit from "../../images/common/edit.svg";
import action from "../../images/common/action.svg";
import clickToUpload from "../../images/common/click_upload.svg";
import successIcon from "../../images/common/icon_approved.svg";
import failureIcon from "../../images/common/icon_declined.svg";
import Modal from "react-modal";
import axios from "axios";
import { useAuth } from "../auth/Auth";

const AddQuizQuestions = () => {
  const [addQuiz, setAddQuiz] = useState(false);
  const [editQuiz, setEditQuiz] = useState(false);
  const [questionOpt, setQuestionOpt] = useState(false);
  const [manualUpload, setManualUpload] = useState(false);
  const [quizFileUpload, setQuizFileUpload] = useState(false);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(false);
  const [allQuiz, setAllQuiz] = useState(false);

  const [loadingPage, setLoadingPage] = useState(true);

  //const openAddQuiz = () => setAddQuiz(!addQuiz);
  const openQuestion = () => setQuestionOpt(!questionOpt);
  const openQuizManualUpload = () => setManualUpload(!manualUpload);
  const openQuizFileUpload = () => setQuizFileUpload(!quizFileUpload);
  const { token } = useAuth;
  const [selectedQuizId, setSelectedQuizId] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState([]);

  const [isQuizActionOpen, setIsQuizActionOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, [token]);

  const fetchData = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/all-quiz`;
    axios
      .get(endpointUrl, config)
      .then((response) => {
        if (response.data) {
          const totalEntries = Object.keys(response.data).length;
          setAllQuiz(totalEntries);
          //console.log(response.data);
          const sortedData = Object.values(response.data).sort(
            (a, b) => b.id - a.id
          );

          const sortedDataObject = {};
          sortedData.forEach((item, index) => {
            sortedDataObject[index] = item;
          });
          setAllQuiz(sortedDataObject);
          setLoadingPage(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const openQuizAction = (id) => {
    setIsQuizActionOpen(true);
    setSelectedQuizId(id);
    document.body.style.overflow = "hidden";
  };

  const closeQuizAction = () => {
    setIsQuizActionOpen(false);
    document.body.style.overflow = "auto";
  };

  const openAddQuiz = () => {
    setAddQuiz(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setAddQuiz(false);
    setEditQuiz(false);
    document.body.style.overflow = "auto";
  };

  const savingApproval = (status) => {
    // console.log(status);
    setSaving(status);
  };

  const handleMessage = (status, message) => {
    setMessage({
      status,
      message,
    });
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return (
    <>
      <div className="lg:tw-w-[96%] tw-overflow-x-hidden tw-w-[90%] tw-px-[5%] tw-mt-12 lg:tw-px-[2%] tw-pb-12">
        <div className="tw-flex tw-justify-between tw-align-center tw-mb-4 tw-mt-3">
          <div className="uaa-subheader tw-self-center tw-mt-6">1 Added</div>
        </div>

        <QuizSettingMenu />

        <div className="add-quiz-title tw-mb-12">
          <div className="add-quiz-text"> Add Quiz Questions</div>
          <div className="add-quiz-btn" onClick={openAddQuiz}>
            + Add Quiz
          </div>
        </div>
        {allQuiz.length === 0 ? (
          <div className="quiz-table-empty">
            <img src={not_found} alt="notfound" />
          </div>
        ) : (
          Object.values(allQuiz).map((quiz, index) => (
            <div className="list-quiz-item tw-cursor-pointer " key={quiz.id}>
              <div className="tw-flex tw-justify-center tw-items-center tw-gap-[10px] lg:tw-gap-[37px]">
                <div className="quiz-item-no">{index + 1}</div>
                <div>{quiz.name}</div>
              </div>

              <div className="tw-flex tw-justify-center tw-items-center tw-gap-[10px] lg:tw-gap-[37px]">
                <div>
                  <img
                    src={edit}
                    alt="edit"
                    className="p-cursor"
                    onClick={() => {
                      setEditQuiz(true);
                      setSelectedQuiz(quiz);
                    }}
                  />
                </div>
                <div>
                  <img
                    src={action}
                    alt="action"
                    className="tw-cursor-pointer"
                    onClick={() => openQuizAction(quiz.id)}
                  />
                  {isQuizActionOpen && (
                    <>
                      {selectedQuizId === quiz.id && (
                        <div>
                          <div className="triangle tw-hidden"></div>
                          <div className="ad-question-overlay">
                            <div
                              className="ad-question-opt"
                              onClick={() => setQuestionOpt(true)}
                            >
                              Add Questions
                            </div>
                            <Link
                              className="ad-question-opt"
                              to="/admin/view-quiz-questions"
                            >
                              View Questions
                            </Link>
                          </div>
                        </div>
                      )}
                      <div
                        className="popup-overlay"
                        onClick={closeQuizAction}
                      ></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {questionOpt && (
        <ChooseQuizOption
          openQuestion={openQuestion}
          openQuizManualUpload={openQuizManualUpload}
          openQuizFileUpload={openQuizFileUpload}
        />
      )}
      {addQuiz && (
        <AddQuiz
          isOpen={addQuiz}
          onRequestClose={closeModal}
          token={token}
          savingApproval={savingApproval}
          handleMessage={handleMessage}
        />
      )}

      {editQuiz && (
        <EditQuiz
          isOpen={editQuiz}
          selectedQuiz={selectedQuiz}
          onRequestClose={closeModal}
          token={token}
          fetchData={fetchData}
          savingApproval={savingApproval}
          handleMessage={handleMessage}
        />
      )}

      {quizFileUpload && (
        <UploadQuizFile
          openQuizFileUpload={openQuizFileUpload}
          token={token}
          selectedQuizId={selectedQuizId}
          closeQuizAction={closeQuizAction}
          savingApproval={savingApproval}
          handleMessage={handleMessage}
        />
      )}
      {manualUpload && (
        <QuizManualUpload
          openQuizManualUpload={openQuizManualUpload}
          token={token}
          selectedQuizId={selectedQuizId}
          closeQuizAction={closeQuizAction}
          savingApproval={savingApproval}
          handleMessage={handleMessage}
        />
      )}

      {saving && (
        <div className="message-overlay">
          <div
            className="message"
            style={{ width: "300px", padding: "40px 66px" }}
          >
            <img src={loading} alt="loading" />
          </div>
        </div>
      )}

      {message && (
        <div className="message-overlay">
          <div className="message">
            <div>
              <img
                src={message.status === "success" ? successIcon : failureIcon}
                alt="Success"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
            <div className="message-text tw-mt-3">{message.message}</div>
            <div className="tw-w-full tw-flex tw-justify-center">
              <button
                type="button"
                className=" tw-bg-blue-700 tw-w-[50%] tw-text-white tw-p-2 tw-cursor-pointer tw-border-0 tw-rounded-sm tw-mt-3"
                onClick={() => {
                  setMessage(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ChooseQuizOption = ({
  openQuestion,
  openQuizManualUpload,
  openQuizFileUpload,
}) => {
  const handleClose = () => {
    openQuestion();
  };

  const handleOpenManual = () => {
    openQuizManualUpload();
    openQuestion();
  };

  const handleFileUpload = () => {
    openQuizFileUpload();
    openQuestion();
  };

  return (
    <div className="message-overlay" onClick={handleClose}>
      <div className="message" style={{ width: "400px" }}>
        <div className="quiz-modal-header">
          <div className="quiz-modal-header-title">Choose Upload Method</div>
          <div className="quiz-modal-header-close" onClick={handleClose}>
            <img src={close} alt="close" />
          </div>
        </div>

        <div className="quiz-modal-body w-full tw-mt-5">
          <div className="w-full tw-flex tw-justify-center tw-items-center tw-flex-col">
            <button
              className="quiz-modal-btn tw-mt-5 be-none"
              onClick={handleFileUpload}
            >
              Upload Quiz File
            </button>
            <button
              className="quiz-modal-btn tw-mt-2"
              onClick={handleOpenManual}
            >
              Type Manually
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddQuiz = ({
  isOpen,
  onRequestClose,
  token,
  savingApproval,
  handleMessage,
}) => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9999,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      top: "50%",
      left: "50%",
      width: "80%",
      maxWidth: "800px",
      marginRight: "-50%",
      minHeight: "580px",
      transform: "translate(-50%, -50%)",
    },
  };

  const [formData, setFormData] = useState({
    name: "",
    pass_score: "",
    failed_score: "",
    time: "",
    no_of_question: "",
    sum_scores: "",
    instruction: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    pass_score: "",
    failed_score: "",
    time: "",
    no_of_question: "",
    sum_scores: "",
    instruction: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        return value.length < 3
          ? "Name must be at least 3 characters long"
          : "";
      case "pass_score":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Pass score must be a number";
      case "failed_score":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Fail score must be a number";
      case "no_of_question":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "No of question must be a number";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const key in formData) {
      const error = validateInput(key, formData[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    onRequestClose();
    savingApproval(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/create-quiz`,
        formData,
        config
      )
      .then((response) => {
        // console.log(response);

        if (response.data.success === true) {
          savingApproval(false);
          // console.log("Form submission successful!", response.data);
          handleMessage("success", "Quiz added success!");
        }
      })
      .catch((error) => {
        console.error("Form submission failed!", error);
        savingApproval(false);
        handleMessage("success", "Error creating quiz!");
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className="tw-w-[94%] tw-mx-[3%] lg:tw-w-[800px] lg:tw-mx-[0%] tw-bg-white">
        <div className="quiz-modal-header ">
          <div className="quiz-modal-header-title">Create Quiz</div>
          <div className="quiz-modal-header-close" onClick={onRequestClose}>
            <img src={close} alt="close" />
          </div>
        </div>

        <form className="tw-m-0 tw-w-full tw-overflow-hidden">
          <div className="tw-w-full tw-flex tw-flex-col tw-h-auto tw-overflow-y-scroll">
            <div className=" tw-grid-cols-1 lg:tw-grid-cols-2 tw-grid tw-gap-2 tw-mr-6">
              <div className="modal-quiz-left">
                <div className="quiz-scores-up">* Quiz Name</div>

                <div className="quiz-input-group">
                  <label className="quiz-model-label">Quiz Name</label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleInputChange}
                    className="quiz-modal-input tw-h-[28px] lg:tw-w-[90%] tw-w-full"
                  />
                  <span style={{ color: "red" }}>{errors.name}</span>
                </div>
                {/* 
                <div className="quiz-input-group">
                  <label className="quiz-model-label">Quiz type</label>
                  <input
                    type="text"
                    name="type"
                    onChange={handleInputChange}
                    className="quiz-modal-input tw-h-[28px] lg:tw-w-[90%] tw-w-full"
                  />
                </div> */}

                <div className="tw-flex tw-gap-6">
                  <div className="quiz-input-group lg:tw-w-[42%] tw-w-full">
                    <label className="quiz-model-label">Time (mins)</label>
                    <div className="cover-input-time">
                      <input
                        type="text"
                        name="time"
                        onChange={handleInputChange}
                        className="quiz-modal-input tw-h-[28px] tw-w-full"
                        style={{
                          border: 0,
                          borderColor: "#dde2ea",
                        }}
                      />
                      <img src={time} alt="time" />
                    </div>
                  </div>
                  <div className="quiz-input-group lg:tw-w-[42%] tw-w-full text-decoration-none">
                    <label className="quiz-model-label">No of Questions</label>
                    <input
                      type="text"
                      name="no_of_question"
                      onChange={handleInputChange}
                      className="quiz-modal-input tw-h-[28px] tw-w-full"
                    />
                    <span style={{ color: "red" }}>
                      {errors.no_of_question}
                    </span>
                  </div>
                </div>

                <div className="quiz-input-group">
                  <label className="quiz-model-label">Quiz Instructions </label>
                  <textarea
                    type="text"
                    name="instruction"
                    onChange={handleInputChange}
                    className="quiz-modal-input tw-h-[28px] lg:tw-w-[90%] tw-w-full"
                  ></textarea>
                  <span style={{ color: "red" }}>{errors.instruction}</span>
                </div>
              </div>
              <div className="modal-quiz-right">
                <div className="quiz-scores-up">* Quiz Scores</div>
                <div className="tw-flex lg:tw-justify-between tw-gap-6">
                  <div className="quiz-input-group">
                    <label className="quiz-model-label">Passed Scores</label>
                    <input
                      type="text"
                      name="pass_score"
                      onChange={handleInputChange}
                      className="quiz-modal-input tw-h-[28px] tw-w-full"
                    />
                    <span style={{ color: "red" }}>{errors.pass_score}</span>
                  </div>
                  <div className="quiz-input-group">
                    <label className="quiz-model-label">Failed Scores</label>
                    <input
                      type="text"
                      name="failed_score"
                      onChange={handleInputChange}
                      className="quiz-modal-input tw-h-[28px] tw-w-full"
                    />
                    <span style={{ color: "red" }}>{errors.failed_score}</span>
                  </div>
                </div>
                <div className="quiz-input-group">
                  <label className="quiz-model-label">Sum Scores</label>
                  <input
                    type="text"
                    name="sum_scores"
                    onChange={handleInputChange}
                    className="quiz-modal-input tw-h-[28px] tw-w-full"
                  />
                  <span style={{ color: "red" }}>{errors.sum_scores}</span>
                </div>

                <div className="quiz-input-group tw-mt-10">
                  <div className="tw-flex tw-justify-between tw-w-full">
                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-x-2">
                      <input
                        type="radio"
                        className="tw-w-6 tw-h-6"
                        name="status"
                        onChange={handleInputChange}
                      />
                      <label className="">Active</label>
                    </div>
                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-x-2">
                      <input
                        type="radio"
                        className="tw-w-6 tw-h-6"
                        name="status"
                      />
                      <label className="">Inactive</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quiz-modal-footer">
              <button className="quiz-modal-btn " onClick={handleSubmit}>
                Add Quiz
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const EditQuiz = ({
  isOpen,
  onRequestClose,
  token,
  fetchData,
  selectedQuiz,
  savingApproval,
  handleMessage,
}) => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      zIndex: 9999,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      top: "50%",
      left: "50%",
      width: "80%",
      maxWidth: "800px",
      marginRight: "-50%",
      minHeight: "580px",
      transform: "translate(-50%, -50%)",
    },
  };

  const [formData, setFormData] = useState({
    name: selectedQuiz.name,
    pass_score: selectedQuiz.pass_score,
    failed_score: selectedQuiz.failed_score,
    time: selectedQuiz.time,
    no_of_question: selectedQuiz.no_of_question,
    sum_scores: selectedQuiz.sum_scores,
    instruction: selectedQuiz.instruction,
  });

  const [errors, setErrors] = useState({
    name: "",
    pass_score: "",
    failed_score: "",
    time: "",
    no_of_question: "",
    sum_scores: "",
    instruction: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({
      ...errors,
      [name]: validateInput(name, value),
    });
  };

  const validateInput = (name, value) => {
    switch (name) {
      case "name":
        return value.length < 3
          ? "Name must be at least 3 characters long"
          : "";
      case "pass_score":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Pass score must be a number";
      case "failed_score":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "Fail score must be a number";
      case "no_of_question":
        return !isNaN(parseFloat(value)) && isFinite(value)
          ? ""
          : "No of question must be a number";
      default:
        return "";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (const key in formData) {
      const error = validateInput(key, formData[key]);
      setErrors({
        ...errors,
        [key]: error,
      });
      if (error) return;
    }

    onRequestClose();
    savingApproval(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}api/edit-quiz/${selectedQuiz.id}`,
        formData,
        config
      )
      .then((response) => {
        if (response.data.success === true) {
          savingApproval(false);
          // console.log("Form submission successful!", response.data);
          handleMessage("success", "Quiz updated successfully!");
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Updating quiz failed:", error);
        savingApproval(false);
        handleMessage("error", "Error updating quiz!");
      });
  };

  const handleDelete = (e) => {
    e.preventDefault();

    onRequestClose();
    savingApproval(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}api/delete-quiz/${selectedQuiz.id}`,
        formData,
        config
      )
      .then((response) => {
        if (response.data.success === true) {
          savingApproval(false);
          // console.log("Form submission successful!", response.data);
          handleMessage("success", "Quiz deleted successfully!");
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Quiz deletion failed!", error);
        savingApproval(false);
        handleMessage("error", "Error deleting quiz!");
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      <div className="tw-w-[94%] tw-mx-[3%] lg:tw-w-[800px] lg:tw-mx-[0%] tw-bg-white">
        <div className="quiz-modal-header ">
          <div className="quiz-modal-header-title">Edit Quiz</div>
          <div className="quiz-modal-header-close" onClick={onRequestClose}>
            <img src={close} alt="close" />
          </div>
        </div>

        <form className="tw-m-0 tw-w-full tw-overflow-hidden">
          <div className="tw-w-full tw-flex tw-flex-col tw-h-auto tw-overflow-y-scroll">
            <div className=" tw-grid-cols-1 lg:tw-grid-cols-2 tw-grid tw-gap-2 tw-mr-6">
              <div className="modal-quiz-left">
                <div className="quiz-scores-up">* Quiz Name</div>

                <div className="quiz-input-group">
                  <label className="quiz-model-label">Quiz Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="quiz-modal-input tw-h-[28px] lg:tw-w-[90%] tw-w-full"
                  />
                  <span style={{ color: "red" }}>{errors.name}</span>
                </div>

                <div className="tw-flex tw-gap-6">
                  <div className="quiz-input-group lg:tw-w-[42%] tw-w-full">
                    <label className="quiz-model-label">Time (mins)</label>
                    <div className="cover-input-time">
                      <input
                        type="text"
                        name="time"
                        onChange={handleInputChange}
                        value={formData.time}
                        className="quiz-modal-input tw-h-[28px] tw-w-full"
                        style={{
                          border: 0,
                          borderColor: "#dde2ea",
                        }}
                      />
                      <img src={time} alt="time" />
                    </div>
                  </div>
                  <div className="quiz-input-group lg:tw-w-[42%] tw-w-full text-decoration-none">
                    <label className="quiz-model-label">No of Questions</label>
                    <input
                      type="text"
                      name="no_of_question"
                      value={formData.no_of_question}
                      onChange={handleInputChange}
                      className="quiz-modal-input tw-h-[28px] tw-w-full"
                    />
                    <span style={{ color: "red" }}>
                      {errors.no_of_question}
                    </span>
                  </div>
                </div>

                <div className="quiz-input-group">
                  <label className="quiz-model-label">Quiz Instructions </label>
                  <textarea
                    type="text"
                    name="instruction"
                    value={formData.instruction}
                    onChange={handleInputChange}
                    className="quiz-modal-input tw-h-[28px] lg:tw-w-[90%] tw-w-full"
                  ></textarea>
                  <span style={{ color: "red" }}>{errors.instruction}</span>
                </div>
              </div>
              <div className="modal-quiz-right">
                <div className="quiz-scores-up">* Quiz Scores</div>
                <div className="tw-flex lg:tw-justify-between tw-gap-6">
                  <div className="quiz-input-group">
                    <label className="quiz-model-label">Passed Scores</label>
                    <input
                      type="text"
                      name="pass_score"
                      value={formData.pass_score}
                      onChange={handleInputChange}
                      className="quiz-modal-input tw-h-[28px] tw-w-full"
                    />
                    <span style={{ color: "red" }}>{errors.pass_score}</span>
                  </div>
                  <div className="quiz-input-group">
                    <label className="quiz-model-label">Failed Scores</label>
                    <input
                      type="text"
                      name="failed_score"
                      value={formData.failed_score}
                      onChange={handleInputChange}
                      className="quiz-modal-input tw-h-[28px] tw-w-full"
                    />
                    <span style={{ color: "red" }}>{errors.failed_score}</span>
                  </div>
                </div>
                <div className="quiz-input-group">
                  <label className="quiz-model-label">Sum Scores</label>
                  <input
                    type="text"
                    name="sum_scores"
                    value={formData.sum_scores}
                    onChange={handleInputChange}
                    className="quiz-modal-input tw-h-[28px] tw-w-full"
                  />
                  <span style={{ color: "red" }}>{errors.sum_scores}</span>
                </div>

                <div className="quiz-input-group tw-mt-10">
                  <div className="tw-flex tw-justify-between tw-w-full">
                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-x-2">
                      <input
                        type="radio"
                        className="tw-w-6 tw-h-6"
                        name="status"
                        onChange={handleInputChange}
                      />
                      <label className="">Active</label>
                    </div>
                    <div className="tw-flex tw-justify-center tw-items-center tw-gap-x-2">
                      <input
                        type="radio"
                        className="tw-w-6 tw-h-6"
                        name="status"
                      />
                      <label className="">Inactive</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-justify-between tw-w-full tw-gap-x-2">
              <button
                className="quiz-modal-btn tw-bg-[#380d0d]"
                onClick={handleDelete}
              >
                Delete Quiz
              </button>
              <button className="quiz-modal-btn" onClick={handleSubmit}>
                Edit Quiz
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const QuizManualUpload = ({
  openQuizManualUpload,
  token,
  savingApproval,
  handleMessage,
  selectedQuizId,
  closeQuizAction,
}) => {
  const handleClose = () => {
    openQuizManualUpload();
  };
  const [selectedOption, setSelectedOption] = useState("default");
  const [multiple, setMultiple] = useState(true);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    switch (selectedValue) {
      case "multiple":
        setMultiple(true);
        break;
      case "text":
        setMultiple(false);
        break;
      default:
        setMultiple(true);
    }
  };

  const [question, setQuestion] = useState("");

  const [inputValues, setInputValues] = useState([
    { option_text: "", is_correct: false },
    { option_text: "", is_correct: false },
    { option_text: "", is_correct: false },
    { option_text: "", is_correct: false },
  ]);

  const handleInputChange = (index, fieldName, value) => {
    setInputValues((prevInputValues) => {
      const updatedValues = [...prevInputValues];
      updatedValues[index][fieldName] = value;
      return updatedValues;
    });
  };

  const handleRadioChange = (index) => {
    setInputValues((prevInputValues) => {
      const updatedValues = prevInputValues.map((option, i) => ({
        ...option,
        is_correct: i === index,
      }));
      return updatedValues;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const hasEmptyOptionText = Object.values(inputValues).some(
      (option) => option.option_text.trim() === ""
    );

    const areAllAnswersFalse = inputValues.every(
      (option) => option.is_correct === false
    );

    const convertedOptions = inputValues.map((option, index) => ({
      option_text: option.option_text,
      is_correct: option.is_correct,
    }));

    const modifiedFormData = {
      question_text: question,
      options: convertedOptions,
    };

    if (hasEmptyOptionText || question === "") {
      handleMessage("error", "You need to fill the inputs!");
    } else if (areAllAnswersFalse) {
      handleMessage("error", "You need to select an answer!");
    } else {
      console.log(modifiedFormData);

      savingApproval(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}api/quizzes/${selectedQuizId}/add-question`,
          modifiedFormData,
          config
        )
        .then((response) => {
          if (response.data.success) {
            savingApproval(false);
            console.log("Form submission successful!", response.data);
            setInputValues([
              { option_text: "", is_correct: false },
              { option_text: "", is_correct: false },
              { option_text: "", is_correct: false },
              { option_text: "", is_correct: false },
            ]);
            openQuizManualUpload();
            handleMessage("success", "Question has been saved!");
            closeQuizAction();
          }
        })
        .catch((error) => {
          savingApproval(false);
          console.error("Form submission failed!", error);
        });
    }
  };

  return (
    <div className="message-overlay tw-px-[4%] lg:tw-px-[0%] tw-w-[92%] lg:tw-w-full">
      <div className="message tw-py-[50px] tw-px-[26px] lg:tw-px-[66px] tw-w-[500px] tw-max-h-[83vh] tw-overflow-y-scroll">
        <div className="quiz-modal-header">
          <div className="quiz-modal-header-title">Add Question</div>
          <div className="quiz-modal-header-close" onClick={handleClose}>
            <img src={close} alt="close" />
          </div>
        </div>

        <div className="quiz-modal-body">
          <div className="add-question-title">* Quiz Question</div>
          <form onSubmit={handleSubmit}>
            <div className="quiz-input-group ph-color">
              <label className="quiz-model-label">Question </label>
              <textarea
                type="text"
                className="quiz-modal-input tw-h-[28px] tw-w-[inherit]"
                placeholder="|"
                name="question_text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                required
              ></textarea>
            </div>

            <div className="tw-flex tw-justify-between tw-mt-4">
              <div className="quiz-answer-option">Answer Options</div>
              <select
                className="quiz-select-tag"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="multiple">Multiple Options</option>
                <option value="text">Text Field</option>
              </select>
            </div>
            {multiple === true ? (
              <div className="tw-flex tw-flex-col tw-mt-4 tw-gap-3">
                {inputValues.map((option, index) => (
                  <div
                    className="answer-list tw-h-[58px] tw-bg-white tw-rounded tw-border tw-border-zinc-200 tw-justify-start tw-items-center  tw-inline-flex"
                    key={index}
                  >
                    <label className="tw-flex tw-justify-start tw-w-12">
                      <input
                        type="radio"
                        name="imageOption"
                        checked={option.is_correct}
                        onChange={() => handleRadioChange(index)}
                        className="visibility-hidden display-none"
                      />
                      <img
                        src={
                          option.is_correct === true
                            ? option_selected
                            : option_empty
                        }
                        alt="selectedOrNot"
                        onChange={() => handleRadioChange(index)}
                      />
                    </label>
                    <input
                      className="quiz-answer-text tw-w-full tw-border-0"
                      placeholder="Answer"
                      name="option_one"
                      value={option.option}
                      required
                      onChange={(e) =>
                        handleInputChange(index, "option_text", e.target.value)
                      }
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="quiz-input-group ph-color">
                <textarea
                  type="text"
                  className="quiz-modal-input tw-h-[28px] tw-w-[inherit] tw-my-3"
                  placeholder="|"
                  name=""
                  disabled
                ></textarea>
              </div>
            )}
          </form>
        </div>

        <div className="quiz-modal-footer mt-4">
          <button className="quiz-modal-btn" onClick={handleSubmit}>
            Add Question
          </button>
        </div>
      </div>
    </div>
  );
};

const UploadQuizFile = ({
  openQuizFileUpload,
  token,
  savingApproval,
  handleMessage,
  selectedQuizId,
  closeQuizAction,
}) => {
  const handleClose = () => {
    openQuizFileUpload();
  };

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("csv_file", selectedFile);

    if (selectedFile) {
      savingApproval(true);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}api/quizzes/${selectedQuizId}/csv-bulk-upload-questions`,
          formData,
          config
        )
        .then((response) => {
          if (response.data.success) {
            savingApproval(false);
            console.log("Form submission successful!", response.data);
            openQuizFileUpload();
            handleMessage("success", "Bulk upload is successful!");
            closeQuizAction();
          }
        })
        .catch((error) => {
          savingApproval(false);
          console.error("Form submission failed!", error);
        });
    } else {
      handleMessage("error", "You need to fill the inputs!");
    }
  };

  const handleDownload = (event) => {
    event.preventDefault();

    const downloadLink = document.createElement("a");
    const fileUrl = process.env.PUBLIC_URL + "/bulk_question.csv";

    downloadLink.href = fileUrl;
    downloadLink.download = "bulk_question.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="message-overlay tw-px-[4%] lg:tw-px-[0%] tw-w-[92%] lg:tw-w-full">
      <div className="message tw-py-[50px] tw-px-[26px] lg:tw-px-[66px] tw-w-[500px] tw-max-h-[83vh] tw-overflow-y-scroll">
        <div className="quiz-modal-header">
          <div className="quiz-modal-header-title">Upload Quiz Questions</div>
          <div className="quiz-modal-header-close" onClick={handleClose}>
            <img src={close} alt="close" />
          </div>
        </div>
        <form>
          <div className="quiz-modal-body tw-flex tw-justify-center tw-items-center tw-mt-5">
            <div className="w-50 align-self-center">
              <button
                onClick={handleDownload}
                className="tw-w-full tw-self-center tw-mb-2 tw-cursor-pointer"
              >
                <i className="fa-solid fa-download"></i> Download Sample (.csv)
              </button>
              <div className="upload-file-title tw-mb-2">Upload File</div>
              <input
                type="file"
                onChange={handleFileChange}
                className="tw-hidden"
                ref={fileInputRef}
                accept=".csv"
              />
              <img
                src={clickToUpload}
                alt="clicktoupload"
                onClick={handleDivClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className="tw-cursor-pointer"
              />
              {selectedFile ? (
                <p>Selected File: {selectedFile.name}</p>
              ) : (
                <p></p>
              )}
            </div>
          </div>

          <div className="tw-mt-5">
            <button className="quiz-modal-btn " onClick={handleSubmit}>
              Save File
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddQuizQuestions;
