import React from "react";
import successIcon from "../../images/common/icon_approved.svg";
import failureIcon from "../../images/common/icon_declined.svg";

const StatusMessage = ({ message, setMessage }) => {
  return (
    <div className="message-overlay">
      <div className="message">
        <div>
          <img
            src={message.status === "success" ? successIcon : failureIcon}
            alt="Success"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
        <div className="message-text tw-mt-3">{message.message}</div>
        <div className="tw-w-full tw-flex tw-justify-center">
          <button
            type="button"
            className=" tw-bg-blue-700 tw-w-[50%] tw-text-white tw-p-2 tw-cursor-pointer tw-border-0 tw-rounded-sm tw-mt-3"
            onClick={() => {
              setMessage(null);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusMessage;
