import React, { useEffect, useState } from "react";
import { QuizSettingMenu } from "../common/QuizSettingMenu";
import { Outlet, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../auth/Auth";
import action from "../../images/common/action.svg";
import loading from "../../images/common/loading.svg";
import close from "../../images/common/closes.svg";
import formatDate from "../common/FormatDate";

const QuizScores = () => {
  const location = useLocation();
  const [myList, setMyList] = useState([]);
  const [totalUsers, setTotalUsers] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingPage, setLoadingPage] = useState(true);
  const itemsPerPage = 5; // Number of items to display per page
  const { token } = useAuth();
  const [applicant, setApplicant] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/all-quiz-results`;
    axios
      .get(endpointUrl, config)
      .then((response) => {
        setTotalUsers(response.data.length);
        // console.log(response.data);
        setMyList(response.data);
        setLoadingPage(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [token]);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Calculate the total number of pages
  const totalItems = myList.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const openApplicantDetails = () => {
    console.log(applicant);
    setOpenDetails(!openDetails);
  };

  if (loadingPage) {
    return (
      <div className="tw-text-center tw-mt-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
        <img src={loading} alt="loader" className="tw-mb-5" />
        Loading...
      </div>
    );
  }

  return location.pathname !== "/admin/quiz-scores" ? (
    <Outlet />
  ) : (
    <>
      <div className="uaa-container">
        <div className="tw-flex tw-justify-between tw-align-center tw-mb-4 tw-mt-3">
          <div className="uaa-subheader tw-self-center tw-mt-6">
            {totalUsers} Participant(s)
          </div>
        </div>

        <QuizSettingMenu />

        <table className="table-quiz">
          <thead>
            <tr>
              <th>Name</th>
              <th>Quiz Type</th>
              <th>Quiz Scores</th>
              <th>Date Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {Object.values(myList).map((person, index) => (
              <tr key={index}>
                <td>{person.name}</td>
                <td>{person.email}</td>
                <td>{person.overall_score}</td>
                <td>{formatDate(person.updated_at)}</td>
                <td
                  onClick={() => {
                    setApplicant(person);
                    openApplicantDetails();
                  }}
                >
                  <img src={action} alt="action" className="cursor-pointer" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <div>
            <h2>
              Showing {""}
              <span>Page {currentPage} of 1</span>
            </h2>
          </div>
          <div className="paginationbutton">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <div className="current_page">{currentPage}</div>
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={endIndex >= totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {openDetails && (
        <ApplicantDetails
          openApplicantDetails={openApplicantDetails}
          applicant={applicant}
        />
      )}
    </>
  );
};

const ApplicantDetails = ({ openApplicantDetails, applicant }) => {
  const handleClose = () => {
    openApplicantDetails();
  };

  return (
    <div className="message-overlay">
      <div className="message" style={{ width: "600px", padding: "50px 66px" }}>
        <div className="quiz-modal-header">
          <div className="quiz-modal-header-title">Applicant Details</div>
          <div className="quiz-modal-header-close" onClick={handleClose}>
            <img src={close} alt="close" />
          </div>
        </div>

        <div className="quiz-modal-body">
          <div className="quiz-input-group">
            <input
              type="text"
              className=" quiz-modal-input w-100 tw-h-[22px] readonly"
              value={applicant.name}
            />
          </div>
          <div className="quiz-input-group">
            <input
              type="text"
              className=" quiz-modal-input w-100 tw-h-[22px] readonly"
              value={applicant.email}
            />
          </div>
          <div className="quiz-input-group">
            <input
              type="text"
              className=" quiz-modal-input w-100 tw-h-[22px] readonly"
              value={applicant.phone}
            />
          </div>

          <div className="quiz-input-group col-6">
            <label className="quiz-model-label">Quiz Score</label>
            <input
              type="text"
              className="quiz-modal-input input-w-300 tw-h-[22px]"
              value={
                applicant.overall_score ? applicant.overall_score : "Not yet"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizScores;
