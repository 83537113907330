import React from "react";

const AccademyDateFormat = ({ originalDate }) => {
  const convertDateFormat = (inputDate) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = new Date(inputDate).toLocaleString("en-US", options);
    return formattedDate;
  };

  const formattedDate = convertDateFormat(originalDate);

  return <span>{formattedDate}</span>;
};

export default AccademyDateFormat;
