import React, { useState } from "react";
import BreadCrumb from "../common/BreadCrumb";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    subject: "",
    message: "",
  });

  const [subscribed, setSubscribed] = useState(false);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Real-time validation
    const newErrors = { ...errors };

    switch (name) {
      case "name":
        newErrors.name = value.trim() === "" ? "Name is required" : "";
        break;
      case "phone_number":
        newErrors.phone_number = /^\d{11}$/.test(value)
          ? ""
          : "phone_number number must be 11 digits";
        break;
      case "email":
        newErrors.email = /\S+@\S+\.\S+/.test(value)
          ? ""
          : "Invalid email address";
        break;
      case "subject":
        newErrors.subject = value.trim() === "" ? "Subject is required" : "";
        break;
      case "message":
        newErrors.message = value.trim() === "" ? "Message is required" : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!/^\d{11}$/.test(formData.phone_number)) {
      newErrors.phone_number = "phone_number number must be 11 digits";
      isValid = false;
    }

    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }

    if (!formData.subject.trim()) {
      newErrors.subject = "Subject is required";
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const toastAction = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/contact`;
        const response = axios.post(endpointUrl, formData);
        await toast.promise(
          response,
          {
            pending: "Submitting request",
            success: "Your message has been submitted!",
            error: "Error Submitting request!",
          },
          toastAction
        );
        setFormData({
          name: "",
          phone_number: "",
          email: "",
          subject: "",
          message: "",
        });
        setErrors({});
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <main class="main-area fix">
      <BreadCrumb name="Contact Us" location="Contact" />
      <ToastContainer />
      <section class="contact-area section-py-120">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="contact-info-wrap">
                <h2 class="title">Keep In Touch With Us</h2>
                <p>Drop a message for us, we will get back to you shortly</p>
                <ul class="list-wrap">
                  <li>
                    <div class="icon">
                      <i class="flaticon-pin-1"></i>
                    </div>
                    <div class="content">
                      <p>
                        3rd Floor, Lanre Shittu House, Mabushi, Abuja. 900108
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="flaticon-phone_number-call"></i>
                    </div>
                    <div class="content">
                      <a href="tel:0123456789">+234 913 716 0848</a>
                      <a href="tel:0123456789">+234 913 157 4290</a>
                    </div>
                  </li>
                  <li>
                    <div class="icon">
                      <i class="flaticon-email"></i>
                    </div>
                    <div class="content">
                      <a href="mailto:info@uniccongroup.com">
                        info@uniccongroup.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-7">
              <div class="contact-form-wrap">
                <h4 class="title">Get in Touch</h4>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-grp">
                      <input
                        name="name"
                        type="text"
                        placeholder="Name *"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <span className="text-danger">{errors.name}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-grp">
                      <input
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        type="email"
                        placeholder="E-mail *"
                        required
                      />
                      {errors.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-grp">
                      <input
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        type="number"
                        placeholder="phone_number *"
                        required
                      />
                      {errors.phone_number && (
                        <span className="text-danger">
                          {errors.phone_number}
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-grp">
                      <input
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        type="text"
                        placeholder="Your Subject *"
                        required
                      />
                      {errors.subject && (
                        <span className="text-danger">{errors.subject}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div class="form-grp">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                    required
                  ></textarea>
                  {errors.message && (
                    <span className="text-danger">{errors.message}</span>
                  )}
                </div>
                <button type="submit" class="btn" onClick={handleSubmit}>
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="contact-map">
        {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48409.69813174607!2d-74.05163325136718!3d40.68264649999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25bae694479a3%3A0xb9949385da52e69e!2sBarclays%20Center!5e0!3m2!1sen!2sbd!4v1684309529719!5m2!1sen!2sbd"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15759.15120721269!2d7.4481568!3d9.0830863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e0acf9b6510b5%3A0xa56b8b0577076e90!2sUniccon%20Academy!5e0!3m2!1sen!2sus!4v1709644883262!5m2!1sen!2sus"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          title="Uniccon Academy Map"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      {subscribed && (
        <div className="lg:tw-w-[400px] tw-w-[95%] tw-fixed tw-shadow-2xl tw-z-[9999] tw-h-auto tw-bg-[#124412] tw-px-[20px] tw-py-3 tw-top-[10px] lg:tw-right-5 tw-right-2 tw-rounded-[10px] tw-text-white tw-flex tw-justify-between tw-items-center">
          Email Subscribed Successfully!{" "}
          <button
            className="btn-danger tw-rounded"
            onClick={() => {
              setSubscribed(false);
            }}
          >
            Close
          </button>
        </div>
      )}
    </main>
  );
};

export default Contact;
