const AddFaq = ({ faq, setFaq }) => {
  //   const [faq, setFaq] = useState([{ topic: "", content: "" }]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newTopics = [...faq];
    newTopics[index][name] = value;
    setFaq(newTopics);
  };

  const handleAddInput = () => {
    setFaq([...faq, { topic: "", content: "" }]);
  };

  const handleRemoveInput = (index) => {
    const newTopics = [...faq];
    newTopics.splice(index, 1);
    setFaq(newTopics);
  };

  return (
    <div>
      {faq.map((topic, index) => (
        <div key={index}>
          <input
            type="text"
            name="topic"
            placeholder="Question"
            className="academy-admin-input"
            value={topic.topic}
            onChange={(e) => handleInputChange(index, e)}
          />

          <br />

          <textarea
            name="content"
            placeholder="Answer"
            className="academy-admin-input"
            value={topic.content}
            onChange={(e) => handleInputChange(index, e)}
          />

          <br />
          <div className="tw-flex tw-gap-x-2">
            {index === faq.length - 1 && (
              <button
                type="button"
                className="academy-admin-btn tw-text-white"
                onClick={handleAddInput}
              >
                Add
              </button>
            )}
            {index !== 0 && (
              <button
                type="button"
                className="academy-admin-btn tw-bg-[#2d0b0b] tw-text-white"
                onClick={() => handleRemoveInput(index)}
              >
                Remove
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AddFaq;
