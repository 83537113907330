const HomePageFaq = () => {
  return (
    <section class="faq-area tw-py-4">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-9 col-lg-10">
            <div class="faq-wrap">
              <div class="accordion" id="accordionExample">
                <div
                  class="accordion-item"
                  data-aos="fade-right"
                  data-aos-delay="400"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      What makes UNICCON Academy stand out among other tech
                      institutes in Nigeria?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        UNICCON Academy distinguishes itself through its
                        comprehensive curriculum, industry-relevant courses,
                        experienced faculty, state-of-the-art facilities, and a
                        commitment to fostering innovation and entrepreneurship
                        in the tech sector. We prioritize hands-on learning,
                        ensuring our students gain practical skills that are
                        directly applicable in the workplace.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  data-aos="fade-right"
                  data-aos-delay="500"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      What tech courses does UNICCON Academy offer?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        UNICCON Academy offers a wide range of tech courses
                        covering areas such as software development, data
                        science, cybersecurity, artificial intelligence, cloud
                        computing, and more. Our curriculum is designed to meet
                        the evolving needs of the tech industry, providing
                        students with in-demand skills sought after by
                        employers.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What sets UNICCON Academy apart as the best IT training
                      institute in Nigeria?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        Uniccon Academy's commitment to excellence, industry
                        partnerships, job placement assistance, and focus on
                        practical skills development make it the top choice for
                        IT training in Nigeria. Our graduates are equipped with
                        the knowledge and expertise needed to thrive in the
                        rapidly evolving tech industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  data-aos="fade-right"
                  data-aos-delay="700"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      How can I enroll in UNICCON Academy's tech courses?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        Enrolling in Uniccon Academy's tech courses is easy!
                        Simply browse our course offerings, and complete the
                        online registration process. Our admissions team is also
                        available to assist you with any questions or concerns
                        you may have regarding course selection, prerequisites,
                        or financial aid options.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  data-aos="fade-right"
                  data-aos-delay="800"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Are there any scholarships or financial aid available for
                      students at UNICCON Academy?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        Yes, Uniccon Academy offers scholarships and financial
                        aid opportunities for eligible students. We believe in
                        making quality tech education accessible to all aspiring
                        learners, regardless of their financial circumstances.
                        Interested applicants can inquire about scholarship
                        opportunities during the enrollment process.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  data-aos="fade-right"
                  data-aos-delay="900"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      How does UNICCON Academy prepare its graduates for success
                      in the tech industry?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        UNICCON Academy goes beyond theoretical instruction by
                        providing students with real-world projects,
                        internships, and industry certifications that enhance
                        their employability and competitiveness in the job
                        market.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="accordion-item"
                  data-aos="fade-right"
                  data-aos-delay="950"
                >
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      How does UNICCON Academy contribute to the advancement of
                      technology in Nigeria?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body">
                      <p>
                        UNICCON Academy actively collaborates with industry
                        partners, government agencies, and other stakeholders to
                        drive technological innovation and contribute to the
                        growth of Nigeria's tech ecosystem. Through research,
                        training, and community engagement initiatives, we aim
                        to harness the power of technology for social and
                        economic development.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageFaq;
