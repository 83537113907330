import { useEffect } from "react";
import BreadCrumb from "../common/BreadCrumb";
import { useState } from "react";
import axios from "axios";
import logo from "../assets/logo.svg";
import loading from "../../images/common/loading.svg";
import defaultImage from "../../images/home/blocktwo.png";

const Courses = () => {
  const [pricing, setPricing] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [totalCourses, setTotalCourses] = useState([]);

  useEffect(() => {
    const endpointUrl = `${process.env.REACT_APP_BASE_URL}api/all-courses`;

    axios
      .get(endpointUrl)
      .then((response) => {
        console.log(response.data);
        setPricing(response.data);
        const totalEntries = Object.keys(response.data).length;
        setTotalCourses(totalEntries);
        setLoadingPage(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <main className="main-area fix">
      <BreadCrumb name="Our Courses" location="All Courses" />
      {loadingPage ? (
        <div className="tw-text-center tw-mt-80 tw-mb-80 tw-flex tw-flex-col tw-justify-center tw-h-[90px] tw-items-center">
          <img src={loading} alt="loader" className="tw-mb-5" />
          Loading...
        </div>
      ) : (
        <section className="all-courses-area section-py-120">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 order-2 order-lg-0">
                <aside className="courses__sidebar">
                  <div className="shop-widget">
                    <h4 className="widget-title">Filter by Category</h4>
                    <div className="shop-cat-list">
                      <ul className="list-wrap">
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="cat_1"
                            />
                            <label className="form-check-label" htmlFor="cat_1">
                              UI & UX Design
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="cat_2"
                            />
                            <label className="form-check-label" htmlFor="cat_2">
                              Mobile Application
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="cat_3"
                            />
                            <label className="form-check-label" htmlFor="cat_3">
                              Data Science
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="cat_4"
                            />
                            <label className="form-check-label" htmlFor="cat_4">
                              Drone Technology
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="cat_5"
                            />
                            <label className="form-check-label" htmlFor="cat_5">
                              Robotics
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="cat_6"
                            />
                            <label className="form-check-label" htmlFor="cat_6">
                              Development
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="shop-widget">
                    <h4 className="widget-title">Price Type</h4>
                    <div className="shop-cat-list">
                      <ul className="list-wrap">
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="price_1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="price_1"
                            >
                              All (1,550)
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="price_2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="price_2"
                            >
                              Free
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="price_3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="price_3"
                            >
                              Paid
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="shop-widget">
                    <h4 className="widget-title">Difficulty Level</h4>
                    <div className="shop-cat-list">
                      <ul className="list-wrap">
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="difficulty_1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="difficulty_1"
                            >
                              All Levels
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="difficulty_2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="difficulty_2"
                            >
                              Beginner
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="difficulty_3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="difficulty_3"
                            >
                              Intermediate
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="difficulty_4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="difficulty_4"
                            >
                              Expert
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop-top-wrap courses-top-wrap">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="shop-top-left">
                        <p>We found {totalCourses} courses for you</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content-center justify-content-md-end align-items-center">
                        <div className="shop-top-right m-0 ms-md-auto">
                          <select name="orderby" className="orderby">
                            <option value="Default sorting">
                              Default sorting
                            </option>
                            <option value="Sort by popularity">
                              Sort by popularity
                            </option>
                            <option value="Sort by average rating">
                              Sort by average rating
                            </option>
                            <option value="Sort by latest">
                              Sort by latest
                            </option>
                            <option value="Sort by latest">
                              Sort by latest
                            </option>
                          </select>
                        </div>
                        <ul
                          className="nav nav-tabs courses__nav-tabs"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="grid-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#grid"
                              type="button"
                              role="tab"
                              aria-controls="grid"
                              aria-selected="true"
                            >
                              <svg
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 1H2C1.44772 1 1 1.44772 1 2V6C1 6.55228 1.44772 7 2 7H6C6.55228 7 7 6.55228 7 6V2C7 1.44772 6.55228 1 6 1Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16 1H12C11.4477 1 11 1.44772 11 2V6C11 6.55228 11.4477 7 12 7H16C16.5523 7 17 6.55228 17 6V2C17 1.44772 16.5523 1 16 1Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6 11H2C1.44772 11 1 11.4477 1 12V16C1 16.5523 1.44772 17 2 17H6C6.55228 17 7 16.5523 7 16V12C7 11.4477 6.55228 11 6 11Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16 11H12C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17H16C16.5523 17 17 16.5523 17 16V12C17 11.4477 16.5523 11 16 11Z"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="list-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#list"
                              type="button"
                              role="tab"
                              aria-controls="list"
                              aria-selected="false"
                            >
                              <svg
                                width="19"
                                height="15"
                                viewBox="0 0 19 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 6C0.67 6 0 6.67 0 7.5C0 8.33 0.67 9 1.5 9C2.33 9 3 8.33 3 7.5C3 6.67 2.33 6 1.5 6ZM1.5 0C0.67 0 0 0.67 0 1.5C0 2.33 0.67 3 1.5 3C2.33 3 3 2.33 3 1.5C3 0.67 2.33 0 1.5 0ZM1.5 12C0.67 12 0 12.68 0 13.5C0 14.32 0.68 15 1.5 15C2.32 15 3 14.32 3 13.5C3 12.68 2.33 12 1.5 12ZM5.5 14.5H17.5C18.05 14.5 18.5 14.05 18.5 13.5C18.5 12.95 18.05 12.5 17.5 12.5H5.5C4.95 12.5 4.5 12.95 4.5 13.5C4.5 14.05 4.95 14.5 5.5 14.5ZM5.5 8.5H17.5C18.05 8.5 18.5 8.05 18.5 7.5C18.5 6.95 18.05 6.5 17.5 6.5H5.5C4.95 6.5 4.5 6.95 4.5 7.5C4.5 8.05 4.95 8.5 5.5 8.5ZM4.5 1.5C4.5 2.05 4.95 2.5 5.5 2.5H17.5C18.05 2.5 18.5 2.05 18.5 1.5C18.5 0.95 18.05 0.5 17.5 0.5H5.5C4.95 0.5 4.5 0.95 4.5 1.5Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="grid"
                    role="tabpanel"
                    aria-labelledby="grid-tab"
                  >
                    <div className="row courses__grid-wrap row-cols-1 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-1">
                      {pricing.map((course, index) => (
                        <div className="col" key={index}>
                          <div className="courses__item-two shine__animate-item">
                            <div className="courses__item-two-thumb">
                              <a
                                href={`/course/${course.slug}`}
                                className="shine__animate-link"
                              >
                                <img
                                  src={
                                    course.image
                                      ? "https://apis.unicconacademy.com/storage/" +
                                        course.image
                                      : defaultImage
                                  }
                                  alt="img"
                                />
                              </a>
                              <div className="author">
                                <a
                                  href="/#"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    background: "#fff",
                                  }}
                                >
                                  <img src={logo} alt="img" />
                                </a>
                              </div>
                            </div>
                            <div className="courses__item-two-content">
                              <a
                                href={`/course/${course.slug}`}
                                className="courses__item-tag"
                                style={{
                                  backgroundColor: "#E8F9EF",
                                  color: "#04BC53",
                                }}
                              >
                                {course.title}
                              </a>
                              <h5 className="title">
                                <a href={`/course/${course.slug}`}>
                                  {course.title}
                                </a>
                              </h5>
                              <ul className="courses__item-meta list-wrap tw-text-[13px]">
                                <li>
                                  <i className="flaticon-file"></i> 05
                                </li>
                                <li>
                                  <i className="flaticon-timer"></i>{" "}
                                  {course.duration}
                                </li>
                                <li>
                                  <i className="flaticon-user-1"></i>{" "}
                                  {course.id + index}
                                </li>
                              </ul>
                              <div className="courses__item-bottom">
                                <div className="course__price">
                                  <h3 className="price">
                                    &#x20A6;
                                    {course?.learning_paths?.Standard?.price
                                      ? course?.learning_paths?.Standard?.price.toLocaleString()
                                      : ""}
                                  </h3>
                                </div>
                                <div className="courses__item-rating">
                                  <i className="fas fa-star"></i>
                                  <span className="rating-count">(4.2)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <nav className="pagination__wrap mt-30">
                      <ul className="list-wrap">
                        <li className="active">
                          <a href="/#">1</a>
                        </li>
                        <li>
                          <a href="/pricing">2</a>
                        </li>
                        <li>
                          <a href="/pricing">3</a>
                        </li>
                        <li>
                          <a href="/pricing">...</a>
                        </li>
                        <li>
                          <a href="/pricing">5</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </main>
  );
};

export default Courses;
