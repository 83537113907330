import React, { useEffect, useState } from "react";
import "../../admin/css/quiz.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const QuizComponent = () => {
  const initialTime = 3600;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [disableNext, setDisableNext] = useState(true);
  const [clickedOption, setClickedOption] = useState(null);
  const [questions, setQuestions] = useState();
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(initialTime);
  const navigate = useNavigate();

  const letters = ["A", "B", "C", "D"];

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const endpointUrl = `${baseUrl}api/get-quizes`;

    axios
      .get(endpointUrl)
      .then((response) => {
        setQuestions(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, []);

  const optionClicked = (is_correct, optionId) => {
    if (is_correct) {
      setScore(score + 1);
    }
    setClickedOption(optionId);
    setDisableNext(false);
  };

  const handleNext = () => {
    if (clickedOption !== null) {
      const selectedOption = questions[currentQuestion].options.find(
        (option) => option.id === clickedOption
      );

      const data = {
        application_id: 123, // Replace with your application ID
        question_id: questions[currentQuestion].id,
        option_id: selectedOption.id,
        is_correct: selectedOption.is_correct,
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}api/update-score-real-time`,
          data
        )
        .then((response) => {
          if (currentQuestion + 1 < questions.length) {
            setCurrentQuestion(currentQuestion + 1);
            setDisableNext(true);
            setClickedOption(null);
          } else {
            setShowResults(true);
          }
        })
        .catch((error) => {
          // Handle error if the POST request fails
          console.error("Error updating score:", error);
        });
    } else {
      // Handle the case where the user hasn't selected an option
      alert("Please select an option before moving to the next question.");
    }
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timer);
          setShowResults(true);
        }
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup function
  }, [initialTime]);

  return (
    <div className="welc omehead">
      {loading ? (
        <p className="tw-text-center tw-mt-5">Loading...</p>
      ) : showResults ? (
        <div className="welcome">
          <h1 className="headerquizend">
            Thank you for completing the quiz! 🌟 We appreciate your
            participation. Expect to receive an email shortly with further
            details or any necessary follow-up. If you have any immediate
            questions, feel free to reach out. Stay tuned for more updates!
          </h1>

          <button onClick={() => navigate("/")}>Submit</button>
        </div>
      ) : (
        <>
          <div className="quiz-container">
            <div className="quiz-header">
              <div className="question-no">
                Question {currentQuestion + 1}/{questions.length}
              </div>
              <div className="quiz-teaser">Brain Teaser</div>
            </div>
            <div className="quiz-divider">
              <div
                className="quiz-progress"
                style={{
                  width: ((currentQuestion + 1) * 100) / questions.length + "%",
                }}
              ></div>
            </div>

            <div className="quiz-cover" style={{ marginTop: "-30px" }}>
              <div className="quiz-page">
                <div className="quiz-question">
                  {questions[currentQuestion].question_text}
                </div>
                {/* Options */}
                {questions[currentQuestion].options.map((option, index) => {
                  const isClicked = option.id === clickedOption;
                  return (
                    <div
                      key={option.id}
                      onClick={() =>
                        optionClicked(option.is_correct, option.id)
                      }
                      className={
                        isClicked ? "quiz-option selected-ans" : "quiz-option"
                      }
                    >
                      <div className="option-letter">{letters[index]}</div>
                      <div className="option-answer">{option.option_text}</div>
                    </div>
                  );
                })}

                {/* Options */}
                <div className="quiz-footer">
                  <button type="button" className="previous-quiz">
                    Previous
                  </button>
                  <button
                    type="button"
                    className={disableNext ? "next-quiz disabled" : "next-quiz"}
                    disabled={disableNext}
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="quiz-timer">Timer: {formatTime(time)}</div>
    </div>
  );
};

export default QuizComponent;
